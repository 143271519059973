import React, { Component } from 'react';
import axios from 'axios';

class KakaoRedirectHandler extends Component {
    constructor(props) {
        super(props);

        const { search } = this.props.location;
        const urlParams = new URLSearchParams(search)
        const code = urlParams.get('code')
        // alert(code);
        this.state = {
            index: 0,
            code: code,
            json: ''
        }


    }
    componentDidMount = () => {
        // alert('did mount');
        let grant_type = "authorization_code";
        let client_id = process.env.REACT_APP_KAKAO_ID_IOS;
        let code = this.state.code;

        axios.post(`https://kauth.kakao.com/oauth/token?grant_type=${grant_type}&client_id=${client_id}&redirect_uri=${process.env.REACT_APP_KAKAO_URL_IOS}&code=${code}`,
            {
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded;charset=utf-8'
                }
            }).then((res) => {
                // console.log(res)
                // res에 포함된 토큰 받아서 원하는 로직을 하면된다.
                axios.get(`https://kapi.kakao.com/v2/user/me`,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + res.data.access_token
                        }
                    }).then((res) => {
                        // console.log(res)
                        // alert(JSON.stringify(res));ㅜ
                        this.setState({
                            json: JSON.stringify(res)
                        })
                        window.webkit.messageHandlers.appFunction.postMessage(JSON.stringify(res));
                        
                    })

            })
    }
    render() {
        return <>
        {
            this.state.json?
            <div>
                <h6>카카오 인증 완료</h6>
                <br/>
                {/* {this.state.json} */}
            </div>
            :<h6>카카오 인증 중..</h6>
        }
            </>
    }
}

export default KakaoRedirectHandler;