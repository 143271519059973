import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import { BrowserView, MobileView } from 'react-device-detect';
import { BrowserRouter, HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import ReactDOM from 'react-dom';

import Navbarmenu from './components/Navbarmenu';


import Help from './Help';
// css
import './MobileStyle.css';
import './components/Navbar.css';
import './App.css';

import EventMain from './EventPage/EventMain';
import EventSuccess from './EventPage/EventSuccess';
import TheLayout from './Layout';
import KakaoIOS from './routes/kakao';
import KakaoAND from './routes/kakaoand';
import KakaoRedirectHandler from './routes/KakaoRedirectHandeler';
import KakaoRedirectHandlerAnd from './routes/KakaoRedirectHandlerand';
import ReportPrice from './EventPage/ReportPrice';

class App extends Component {

  render() {

    return (
      <div className="App">
        <HashRouter>
          <Switch>
            {/* <Route path='/event' component={EventMain} /> */}
            <Route path='/event/success' component={EventSuccess} />
            <Route path='/event/:userID/:clubID' component={EventMain} />
            <Route path='/event' component={EventMain} />
            <Route path='/reportprice' component={ReportPrice} />
            <Route path='/kakao/and' exact component={KakaoAND} />
            <Route path='/kakao/ios' exact component={KakaoIOS} />
            <Route path='/auth/kakao/callback/ios' component={KakaoRedirectHandler} />
            <Route path='/auth/kakao/callback/and' component={KakaoRedirectHandlerAnd} />
            <Route path='/' component={TheLayout} />
            <Route component={()=><Redirect to='/'/>}/>
            {/* <TheLayout></TheLayout> */}
          </Switch>
        </HashRouter>

        {/* <BrowserView>
            <Navbarmenu />
            <Route path='/' component={Home} />
            <Route exact path='/service' component={Service} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/help' component={Help} />
          </BrowserView>

          <MobileView>
            <Navbarmenu />
            <Route path='/' component={MobileHome} />
            <Route exact path='/service' component={MobileService} />
            <Route exact path='/terms' component={MobileTerms} />
            <Route exact path='/help' component={MobileHelp} />
          </MobileView> */}
      </div>
    );
  }
}

export default App;
