import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Footer from './Footer/Footer';
import Terms from './Terms';
import axios from 'axios'



class Help extends Component {

  constructor(props) {
    super(props);
    this.state = {
      club_name: "",
      manager_name: "",
      phone_number: "",
      question: ""
    }
  }
  moveToHome = () => {
    window.location.href = '/'
  }
  sendQuestion = () => {
    // alert(JSON.stringify(this.state));
    var errorMsg = null;
    if (this.state.club_name.length < 3) {
      errorMsg = '승마장 이름을 제대로 입력해주세요'
    } else if (this.state.manager_name.length < 2) {
      errorMsg = '관리자 성함을 제대로 입력해주세요'
    } else if (this.state.phone_number.length < 9) {
      errorMsg = '연락처를 제대로 입력해주세요'
    } else if (this.state.question.length < 5) {
      errorMsg = '문의내용을 제대로 입력해주세요'
    }
    if (errorMsg && errorMsg.length > 0) {
      alert(errorMsg);
    } else {

      axios.post('http://3.38.226.155:8080/admin/homepage/question', {
        club_name: this.state.club_name,
        manager_name: this.state.manager_name,
        manager_number: this.state.phone_number,
        content: this.state.question
      }).then(res => {
        if (res.data.status == 1) {
          alert('문의가 등록되었습니다');
          this.moveToHome()
        } else {
          alert(res.data.message);
        }
      }).catch(error => {
        alert(error);
      });
    }
  }
  render() {
    return (
      <>
        <Row style={{ backgroundColor: "#12AFDC", height: '280px', width: '100%', padding: 0, margin: 0 }}>
          <Container>
            <Col className="help-header" style={{ textAlign: 'left' }}>
              <h2 style={{ margin: "100px 0px 40px 0px", fontSize: '46px', color: '#fff' }}><span style={{ fontWeight: '200' }}>말타</span>
                <span style={{ fontWeight: '700' }}> 문의하기</span></h2>
              <p style={{ fontSize: '18px', color: '#fff', marginTop: '-34px' }}>작성하신 내용을 바탕으로 친절히 답변드리겠습니다.</p>
            </Col>
          </Container>
        </Row>
        <Container style={{ maxWidth: "800px", marginBottom: "110px" }}>
          <Row style={{ padding: '50px 0px 0px 0px' }}>
            <h2 style={{ fontSize: '36px', padding: '40px 0px 40px 0px' }}>
              <span style={{ fontWeight: '700' }}> 문의하기</span></h2>
              <Form>
            <FormGroup row>
              <Label for="exampleclub" xs={2}>승마클럽 명</Label>
              <Col xs={10}>
                <Input type="club" name="club" id="exampleclub" onChange={(e)=>this.setState({club_name:e.target.value})}/></Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplename" xs={2}>담당자 성함</Label>
              <Col xs={10}>
                <Input type="name" name="name" id="examplename"  onChange={(e)=>this.setState({manager_name:e.target.value})}/></Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplename" xs={2}>담당자 연락처</Label>
              <Col xs={10}>
                <Input type="phone" name="phone" id="examplephone"  onChange={(e)=>this.setState({phone_number:e.target.value})}/></Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleText" xs={2}>문의내용</Label>
              <Col xs={10}>
                <Input className="helptext" type="textarea" rows="8" name="text" id="exampleText"  onChange={(e)=>this.setState({question:e.target.value})}/></Col>
              <FormText color="muted" style={{ textAlign: 'left', margin: '14px 0px 0px 180px' }}>
                원활한 상담을 위해 문의할 내용을 자세히 작성해주세요.<br />
                작성 내용을 바탕으로 친절히 답변드리겠습니다.
              </FormText>
            </FormGroup>
          </Form>
          </Row>
          <Col style={{ margin: '50px 0px 0px 0px' }}>
              <Button onClick={()=>this.sendQuestion()}
               style={{
                backgroundColor: '#12AFDC',
                color: '#fff',
                width: '240px',
                height: '60px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '600',
                border: 'none',
                outline: 'none',
                lineHeight: '0.5px',
                boxShadow: 'none'
              }} >문의 등록</Button>
              <Button 
              onClick={()=>this.moveToHome()}
              style={{
                backgroundColor: '#F8F9FA',
                color: 'rgb(46, 46, 46)',
                width: '240px',
                height: '60px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '600',
                border: 'none',
                outline: 'none',
                lineHeight: '0.5px',
                boxShadow: 'none'
              }} >취소</Button>
          </Col>
        </Container>

        <Footer />

      </>
    );
  }
}

export default Help;







{/* <Container style={{ maxWidth: "740px", paddingTop:"50px"}}>
      <Row>
        <Form action="/" method="POST" style={{textAlign:"left"}}>
        <FormGroup  style={{display:"flex", margin:"0px 0px 40px 0px"}}>
        <Label for="checkAll" style={{display:"flex"}}>
        <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }} name="checkAll" id="checkAll"
      />
          이용약관, 개인정보 수집 및 이용, 개인정보의 수집 및 <br/>이용목적, 개인정보의 보유 및 이용기간에 모두 동의합니다.
        </Label>
      </FormGroup>
        <FormGroup >
        <Label check>
        <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }} name="agreement" id="privacyPolicy0" value="privacyPolicy0" required
      />
          [필수] 수집하는 개인정보 항목 및 수집방법
        </Label>
        
        <Input type="textarea" name="text" id="exampleText" defaultValue="(주)럭스포 홈페이지는 서비스 이용을 위해 필요한 최소한의 개인정보만을 “문의하기” 게시판에서 이용자가 입력한 정보에 한해 수집합니다. 귀하가 (주)럭스포 홈페이지의 서비스를 이용하기 위해서는 연락처를 입력하셔야 하며, 입력하신 정보는 서비스 제공에 사용됩니다." readOnly />
      </FormGroup>
      <FormGroup style={{ paddingTop:"20px"}}>
      <Label check>
      <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }} name="agreement" id="privacyPolicy1" value="privacyPolicy1" required
      />
          [필수] 개인정보의 보유 및 이용기간
        </Label>
        <Input type="textarea" name="text" id="exampleText" defaultValue="(주)럭스포 홈페이지는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 귀하의 개인정보를 즉시 파기합니다.[보유기간] : 문의내용 처리 후 즉시 파기" readOnly />
      </FormGroup>
      
      <FormGroup style={{ paddingTop:"20px"}}>
      <Label check>
      <Checkbox
        inputProps={{ 'aria-label': 'primary checkbox' }} name="agreement" id="privacyPolicy2" value="privacyPolicy2" required
      />
         [필수] 개인정보의 수집 및 이용목적
        </Label>
       <Input type="textarea" name="text" id="exampleText" defaultValue="(주)럭스포 홈페이지는 귀하의 개인정보 수집에 대한 동의를 받고 있습니다. 귀하의 개인정보 수집과 관련하여 (주)럭스포 홈페이지의 개인정보처리방침 또는 이용약관의 내용에 대해 『동의함』버튼 또는 『동의 안 함』 버튼을 클릭할 수 있는 절차를 마련하고 있으며, 귀하가 『동의함』 버튼을 클릭하면 개인 정보 수집에 대해 동의한 것으로 봅니다. [이용목적] : 문의내용 답변 목적" readOnly />
      </FormGroup>
        </Form>
        <Form>
        <Button className="btn-help" style={{margin:'70px 0px 120px 0px'}}>문의 등록</Button>
        </Form>
      </Row>
    </Container> */}