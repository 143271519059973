import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FooterM from './Footer/FooterM';



class MobileTerms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    agreeBtnClicked = () => {
        window.location.href = '/#/help'
      }
    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }

    render() {
        return (
            <>
                <Row style={{ backgroundColor: "#12AFDC", width: '100%', padding: 0, margin: 0 }}>
                    <Row className='container-mobile'>
                        <Row className="help-header" style={{ textAlign: 'left' }}>
                            <h2 style={{ margin: "40px 0px 40px 10px", fontSize: '30px', color: '#fff' }}><span style={{ fontWeight: '200' }}>말타</span>
                                <span style={{ fontWeight: '700' }}> 문의하기</span></h2>
                            <p style={{ fontSize: '14px', color: '#fff', marginTop: '-34px', marginBottom: "34px", marginLeft:'10px' }}>작성하신 내용을 바탕으로 친절히<br />답변드리겠습니다.</p>
                        </Row>
                    </Row>
                </Row>

                <Row className='container-mobile div-align-center' style={{ marginTop: "20px", marginBottom: "70px" }}>
                    <Row style={{ textAlign: "center" }}>
                        <h2 style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <span style={{ fontWeight: '700', fontSize: '20px', color: '#12AFDC' }}>고객님의 소중한 의견에<br />신뢰할 수 있는 최선의 답변</span>
                            <span style={{ fontWeight: '200', fontSize: '20px' }}>을<br />드릴 수 있도록 노력하겠습니다.</span>
                        </h2>
                    </Row>
                    <Row style={{ textAlign: "left", paddingLeft: '10px', margin: 0 }}>

                        <h2 style={{ margin: "30px 0px 30px 0px", fontSize: '22px' }}><span style={{ fontWeight: '200' }}>말타</span>
                            <span style={{ fontWeight: '700' }}> 이용약관</span></h2>

                        <Row style={{ marginBottom: "30px", padding: 0, margin: 0 }}>
                            <h4 style={{ fontWeight: '500', fontSize: '14px' }}>수집하는 개인정보 항목 및 수집방법</h4>
                            <p style={{ fontWeight: '300', fontSize: '12px', lineHeight: "20px" }}>말타 홈페이지는 서비스 이용을 위해 필요한 최소한의 개인정보만을 “문의하기” 게시판에서 이용자가 입력한 정보에 한해 수집합니다. 귀하가
                        말타 홈페이지의 서비스를 이용하기 위해서는 연락처를 입력하셔야 하며, 입력하신 정보는 서비스 제공에 사용됩니다.<br />
                        [수집항목] : 승마클럽 명(필수), 담당자 성함(필수), 담당자 연락처(필수)</p>
                        </Row>
                        <Row style={{ marginBottom: "30px", padding: 0, margin: 0 }}>
                            <h4 style={{ fontWeight: '500', fontSize: '14px' }}>[필수] 개인정보의 보유 및 이용기간</h4>
                            <p style={{ fontWeight: '300', fontSize: '12px', lineHeight: "20px" }}>말타 홈페이지는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 귀하의 개인정보를 즉시 파기합니다.<br />[보유기간] : 문의내용 처리 후 즉시 파기</p>
                        </Row>
                        <Row style={{ marginBottom: "30px", padding: 0, margin: 0 }}>
                            <h4 style={{ fontWeight: '500', fontSize: '14px' }}>[필수] 개인정보의 수집 및 이용목적</h4>
                            <p style={{ fontWeight: '300', fontSize: '12px', lineHeight: "20px" }}>말타 홈페이지는 귀하의 개인정보 수집에 대한 동의를 받고 있습니다. 귀하의 개인정보 수집과 관련하여 말타 홈페이지의 개인정보처리방침 또는 이용약관의 내용에 대해 『동의 및 문의등록』 버튼 또는 『동의 안 함』 버튼을 클릭할 수 있는 절차를 마련하고 있으며, 귀하가 『동의 및 문의등록』 버튼을 클릭하면 개인 정보 수집에 대해 동의한 것으로 봅니다. <br />[이용목적] : 문의내용 답변 목적</p>
                        </Row>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <h4 style={{ fontWeight: '500', fontSize: '14px', padding: '30px 0px 0px 0px' }}>
                            위 약관에 동의하십니까?</h4>
                    </Row>
                    <Row className='container-mobile div-align-center'>
                            <Button style={{
                                backgroundColor: '#12AFDC',
                                color: '#fff',
                                width: '150px',
                                height: '42px',
                                fontSize: '14px',
                                fontWeight: '600',
                                border: 'none',
                                outline: 'none',
                                lineHeight: '0.5px',
                                boxShadow: 'none',
                                padding: 0,
                                margin: '10px'
                            }} onClick={()=>this.agreeBtnClicked()}>동의 및 문의 등록</Button>
                    </Row>
                    <Row className='container-mobile div-align-center'><Button style={{
                        backgroundColor: '#F8F9FA',
                        color: 'rgb(46, 46, 46)',
                        width: '150px',
                        height: '42px',
                        fontSize: '14px',
                        fontWeight: '600',
                        border: 'none',
                        outline: 'none',
                        lineHeight: '0.5px',
                        boxShadow: 'none',
                        padding: 0,
                        margin: '4px 10px 10px 10px'
                    }} onClick={this.toggle}>동의 안 함</Button></Row>
                    <Modal isOpen={this.state.modal} fade={true} toggle={this.toggle} aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <ModalBody style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '20px 12px 0px 12px',
                            fontSize: '14px', fontWeight: '500'
                        }}>
                            문의 등록을 하시려면 '동의 및 문의등록'을 눌러주세요.
                        </ModalBody>
                        <ModalFooter style={{ height: "70px", borderTopColor: "#fff" }}>
                            <Button style={{
                                backgroundColor: '#12AFDC',
                                color: '#fff',
                                width: '80px',
                                padding: 0,
                                margin: 0,
                                height: "36px",
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: '600'
                            }} onClick={this.toggle}>닫기</Button>
                        </ModalFooter>
                    </Modal>

                </Row>
                <FooterM />
            </>

        );
    }
}

export default MobileTerms;