import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { Collapse, CardBody, Card } from 'reactstrap';
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { RiCustomerService2Fill, RiMessage3Line } from "react-icons/ri";
import SimpleSlider2M from './SimpleSlider2M';
import FooterM from './Footer/FooterM';
import './MobileStyle.css';

import axios from 'axios'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { StyleSheet, View, Text, ImageBackground } from 'react-native';

import background from "./img/main/background-image1.jpg";


class Home2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnStatus: 0,
      btnStatus1: 0,
      btnStatus2: 0,
      btnStatus3: 0,
      btnStatus4: 0
    }
  }

  moveToService = () => {
    window.location.href = '/#/service'
  }
  btnBgc = (status) => {
    if (status) {
      return "#F7F7F7"
    } else {
      return '#fff'
    }
  }




  appstoreBtnClicked = () => {
    this.logClick(1, 0);
    window.open('https://apps.apple.com/app/id1532007829', '_blank').focus();
  }

  playStoreBtnClicked = () => {
    this.logClick(0, 1);
    window.open('https://play.google.com/store/apps/details?id=com.timehive.marlta', '_blank').focus();
  }

  logClick = (as, ps) => {

    var log_id = window.sessionStorage.getItem('lid');
    if (log_id > 0) {
      var param = {}
      if (as > 0) { param.click_app_store = 1; }
      if (ps > 0) { param.click_play_store = 1; }
      axios.post('http://3.38.226.155:8080/admin/homepage/logs/click', {
        log_id: log_id,
        param: param
      }).then(res => {
        if (res.data.status == 1) {
          // alert('로그 : ' + JSON.stringify(res.data.data.log_id));
        } else {
          // alert(res.data.message);
        }
      }).catch(error => {
        // alert(error);
      });
    }
  }



  render() {
    return (
      <>
        <Row className="justify-content-md-center" style={{ margin: "90px 0px 30px 0px" }}>

          <h2><span style={{ fontWeight: '200', fontSize: "16px", lineHeight: '34px' }}>휴일에도 전화 받는 일은 이제 그만!</span><br />
            <span style={{ fontWeight: '200', fontSize: "22px" }}>'말타'를 통해</span><br />
            <span style={{ fontWeight: '700', fontSize: "22px" }}> 회원도, 코치도 예약</span><span style={{ fontWeight: '200', fontSize: "22px" }}>이 쉬워집니다</span></h2>
        </Row>
        <Row className='container-mobile'>
          <Row className='mockup-img-mobile'>
            <img style={{ height: '200px', width: '250px' }} src={require('./img/main/member-mockup2.png').default} />
          </Row>
          <Row style={{ textAlign: "center", paddingTop: "5px", paddingBottom: '80px' }} xs={{ size: 4, order: 0, offset: 1 }} className='container-mobile'>
            <h2 style={{ fontWeight: '700', fontSize: "20px" }}>말타는?</h2>
            <p style={{ fontSize: '12px' }}>
              말타는 기승 예약, 스케쥴 관리 등 승마에<br />최적화된 신규 유통채널로 편리하게 승마를<br />즐길 수 있는 서비스입니다.</p>
          </Row>
        </Row>
        <Row style={{ padding: 0, margin: 0 }}>
          <Col className="solution-title" style={{ backgroundColor: "#F8F9FA", margin: "0px 0px 120px 0px" }}>
            <h2 style={{ padding: "70px 0px 0px 0px", fontSize: '22px' }}><span style={{ fontWeight: '200', fontSize: '20px' }}>국내최초!</span><br /><span style={{ fontWeight: '700' }}>승마장의 일정을<br />코치들이 공유</span><span style={{ fontWeight: '200' }}>할 수 있는<br />승마장 관리 솔루션</span></h2>
            <Row style={{ textAlign: "center", justifyContent: "center", alignItems: "center", padding: "30px 0px 90px 0px" }}>
              <Col style={{ padding: "10px 0px" }}>
                <img src={require('./img/main/solution-img1.png').default} style={{ height: "280px" }} /></Col>
              <Col style={{ padding: "10px 0px" }}>
                <img src={require('./img/main/solution-img2.png').default} style={{ height: "280px" }} /></Col>
              <Col style={{ padding: "10px 0px" }}>
                <img src={require('./img/main/solution-img3.png').default} style={{ height: "280px" }} /></Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ margin: '0px 0px 80px 0px' }}>
          <SimpleSlider2M />
        </Row>

        <Row
          style={{ backgroundImage: `url(${background})`, width: '100%', backgroundSize: '100%', margin: 0, padding: 0 }}>
          <Row className='container-mobile' >
            <h2 style={{ color: '#ffff', fontSize: '22px', fontWeight: '700', padding: '80px 0px 20px 0px' }}>베이직부터 시작하세요!
              <br />직접 사용하며 이해 하시는게<br />제일 좋습니다</h2>
          </Row>
          <Row className='container-mobile div-align-center' style={{ padding: 10 }}>
            <Row className="basic-box-mobile div-align-center" >
              <Row className="basic-box-mobile div-align-center" >
                <Row className="basic-message1-mobile">말타 기능을 체험해보고 싶어요</Row>
              </Row>
              <Row className="basic-box-mobile div-align-center" >
                <Row className="basic-message1-mobile">기존 회원관리는 별도로 해도 괜찮아요</Row>
              </Row>
              <Row className="basic-box-mobile div-align-center" >
                <Row className="basic-message1-mobile">일일체험 위주로 운영하고 있어요</Row>
              </Row>
              <Row className="basic-box-mobile div-align-center" >
                <Row className="basic-message2-mobile">BASIC을 추천드립니다!</Row></Row>
            </Row>
            <Row className='container-mobile div-align-center'>
              <img src={require('./img/main/basic-premium-icon.png').default}
                style={{ height: '22px', width: '66px', marginTop: '20px' }} /></Row>
            <Row className="basic-mobile" >
              <Row style={{ justifyContent: 'center' }}>
                <h2>BASIC<br /><span style={{ fontWeight: '200', fontSize: '24px' }}>무료</span></h2>
                <p><span style={{ color: '#888888', fontSize: '12px' }}>승마체험 / 일일기승<br />수수료 10%</span></p>
                <Row style={{
                  textAlign: 'center', display: 'block', marginTop: '2px',
                  alignItems: 'center'
                }}>
                  <Row className="basic1-mobile ">신규 예약 관리</Row>
                  <Row className="basic1-mobile">프로그램 등록</Row>
                  <Row className="basic1-mobile">승마클럽 일정<br />관리(제한)</Row>
                </Row>
                <p><span style={{ color: '#a8a8a8', fontSize: '10px' }}>PG 별도</span></p>
              </Row>
            </Row>
            <h2 style={{ color: '#ffff', fontSize: '22px', fontWeight: '700', padding: '60px 0px 20px 0px' }}>프리미엄은<br />편리하고 다양한 기능들을<br />제공합니다</h2>

            <Row className="premium-box-mobile div-align-center" style={{ textAlign: 'left' }}>
              <Row className='div-align-center'>
                <Row className="premium-message1-mobile">많은 신규회원 유입과 예약을 편리하게 관리하고 싶어요</Row>
              </Row>
              <Row className='div-align-center'>
                <Row className="premium-message1-mobile">회원과 클럽의 모든 관리 기능이 필요해요</Row>
              </Row>
              <Row className='div-align-center'>
                <Row className="premium-message1-mobile">코치들과 실시간으로 스케쥴을 공유하고 싶어요</Row>
              </Row>
              <Row className='div-align-center'>
                <Row className="premium-message2-mobile"><span style={{ color: '#ffe600' }}>★</span> PREMIUM을 추천드립니다!</Row>
              </Row>
            </Row>
            <Row className='container-mobile div-align-center'>
              <img src={require('./img/main/basic-premium-icon.png').default}
                style={{ height: '22px', width: '66px', marginTop: '20px' }} /></Row>
            <Row className="premium-mobile">
              <Row style={{ justifyContent: 'center' }}>
                <h2>PREMIUM<br /><span style={{ fontWeight: '200', fontSize: '24px' }}>30,000원 /월</span></h2>
                <p><span style={{ color: '#888888', fontSize: '12px', lineHeight: '0.5px' }}>승마체험 / 일일기승<br />수수료 10%</span></p>
                <Row style={{
                  textAlign: 'center', display: 'block', marginTop: '2px',
                  alignItems: 'center'
                }}>
                  <Row className="premium1-mobile">모든 회원 예약 관리</Row>
                  <Row className="premium1-mobile">프로그램 등록</Row>
                  <Row className="premium1-mobile">승마클럽 일정 관리</Row>
                  <Row className="premium1-mobile">회원 쿠폰 관리</Row></Row>
                <p><span style={{ color: '#a8a8a8', fontSize: '10px', paddingTop: '20px' }}>PG / VAT. 별도</span></p>
              </Row>
            </Row>
          </Row>

          <Button onClick={() => this.moveToService()} className="btn-help-mobile" style={{ marginBottom: '90px', fontSize: '12px' }} type="button">요금제 자세히 알아보기</Button>

        </Row>


        <Row className='container-mobile'>
          <Row className="join" style={{ margin: '100px 0px 110px 0px' }}>
            <h2 style={{ marginBottom: '40px' }} className='container-mobile'>
              <span style={{ fontWeight: '200', fontSize: "16px" }}>말타 써보고 싶은데, 가입 어떻게 하나요?</span><br />
              <span style={{ fontWeight: '700', fontSize: "26px" }}>말타 가입 절차는<br />간단합니다!</span></h2>
            <Row style={{ margin: 0, padding: 0 }}>
              <Row className="div-align-center" style={{ margin: 0, padding: 0 }}>
                <img src={require('./img/main/join1-1.png').default}
                  style={{ height: "110px", width: '130px' }} />
                <h2><span style={{ color: '#12AFDC', fontSize: '22px', fontWeight: '700' }}>문의하기</span></h2>
                <p><span style={{ fontSize: '14px' }}>문의에 필요한 정보들을<br />적고 문의 등록합니다.</span></p>
              </Row>
              <Row className="container-mobile div-align-center" style={{ margin: '30px 0px', padding: 0 }}>
                <img src={require('./img/main/join1-5.png').default}
                  style={{ height: "40px", width: '40px', padding: 0 }} />
              </Row>
              <Row className="div-align-center">
                <img src={require('./img/main/join1-2.png').default}
                  style={{ height: "110px", width: '130px' }} />
                <h2><span style={{ color: '#12AFDC', fontSize: '22px', fontWeight: '700' }}>서류심사</span></h2>
                <p><span style={{ fontSize: '14px' }}>문의 내용으로 전화 상담 후<br />서류 심사를 시작합니다.</span></p>
              </Row>
              <Row className="container-mobile div-align-center" style={{ margin: '30px 0px', padding: 0 }}>
                <img src={require('./img/main/join1-5.png').default}
                  style={{ height: "40px", width: '40px', padding: 0 }} />
              </Row>
              <Row className="div-align-center">
                <img src={require('./img/main/join1-3.png').default}
                  style={{ height: "110px", width: '130px' }} />
                <h2><span style={{ color: '#12AFDC', fontSize: '22px', fontWeight: '700' }}>승인결과</span></h2>
                <p><span style={{ fontSize: '14px' }}>승인 결과가 나면<br />전화로 연락을 드립니다.</span></p>
              </Row>
            </Row>
          </Row>
        </Row>
        <Row style={{
          backgroundColor: '#f5f5f5', padding: '90px 0px 50px 0px', margin: 0
        }}>
          <Row className="container-mobile" >
            <h2 style={{ fontSize: '26px', padding: '0px 0px 40px 0px' }}><span style={{ fontWeight: '200' }}>PC와 모바일에서</span><br /><span style={{ fontWeight: '700' }}>편리하게 관리하세요!</span>
            </h2>
            <Row className="div-align-center">
              <Row className="div-align-center" style={{ margin: 0, padding: 0 }} >
                <img src={require('./img/main/pc-mobile1.png').default}
                  style={{ height: "190px", width: '200px', margin: 0, padding: 0 }} />
                <p style={{ paddingTop: '30px', fontSize: '14px' }}>말타 PC버전을 통해 회원 관리,<br />마필 관리, 마장 관리, 프로모션 관리를<br />용이하게 할 수 있습니다.</p>
              </Row>
              <Row className="div-align-center" style={{ margin: 0, padding: '60px 0px 30px 0px' }}>
                <img src={require('./img/main/pc-mobile2.png').default}
                  style={{ height: "190px", width: '200px', margin: 0, padding: 0 }} />
                <p style={{ paddingTop: '30px', fontSize: '14px' }}>앱으로 어디서든 누구나<br />승마장 일정을 수정하고 확인할 수 있습니다.<br />지금 바로 앱을 통해 관리하세요.</p>
              </Row>
            </Row>
          </Row></Row>

        <Row className='container-mobile ' style={{ margin: 0, padding: 0 }}>
          <h2 style={{ margin: "150px 0px 40px 0px", fontSize: '28px' }}><span style={{ fontWeight: '200' }}>말타</span>
            <span style={{ fontWeight: '700' }}> 뉴스</span></h2>
          <Row className='div-align-center'>
            <Row className="news1-1" style={{ padding: "30px 0px 10px 0px" }}>
              <a href="https://www.nextunicorn.kr/newsroom/91dca7f059c15e24"
                target="_blank" style={{ textDecoration: "none" }}>
                <img src={require('./img/main/news1-1.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                <Row className="news-title1-1" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                  <span style={{ fontSize: "14px", fontWeight: "400" }}>서비스 무료 이용</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'베이직 요금제 출시'</span>
                </Row>
                {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2021.05.31</Row> */}
                <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>넥스트유니콘</Row>
              </a>
            </Row>
            <Row className="news1-2" style={{ padding: "30px 0px 10px 0px" }}>
              <a href="https://www.nextunicorn.kr/newsroom/527b95a6c4222b68"
                target="_blank" style={{ textDecoration: "none" }}>
                <img src={require('./img/main/news1-2.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                <Row className="news-title1-2" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                  <span style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>한국마사회와</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'업무협약(MOU) 체결'</span>
                </Row>
                {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2021.03.24</Row> */}
                <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>넥스트유니콘</Row>
              </a>
            </Row>
            <Row className="news1-3" style={{ padding: "30px 0px 10px 0px" }}>
              <a href="https://www.nextunicorn.kr/newsroom/d9175a6a18eafb56"
                target="_blank" style={{ textDecoration: "none" }}>

                <img src={require('./img/main/news1-3.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                <Row className="news-title1-3" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                  <span style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>승마 산업 활성화</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'승마장 5곳과 협약 체결'</span>
                </Row>
                {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2020.12.18</Row> */}
                <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>넥스트유니콘</Row>
              </a>
            </Row>

            <Row className="news1-4" style={{ padding: "30px 0px 10px 0px" }}>
              <a href="https://www.edaily.co.kr/news/read?newsId=01380886625864696&mediaCodeNo=257&OutLnkChk=Y"
                target="_blank" style={{ textDecoration: "none" }}>

                <img src={require('./img/main/news1-4.jpg').default} style={{ marginBottom: "5px", height: "180px", width: "256px", cursor: "pointer" }} />
                <Row className="news-title1-4" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                  <span style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>2020 농림축산식품</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'창업경진대회 최우수상'</span>
                </Row>

                {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2020.08.06</Row> */}
                <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>이데일리</Row>

              </a>
            </Row>
          </Row>
        </Row>
        <Row className='container-mobile div-align-center'>
          <h2 style={{ paddingBottom: "30px", fontSize: '28px', paddingTop: '140px' }}><span style={{ fontWeight: '200' }}>말타</span>
            <span style={{ fontWeight: '700' }}> 파트너스</span></h2>
          <Row style={{ justifyContent: "center", alignItems: "center", marginBottom: "60px" }}>
            <Row className='container-mobile div-align-center'>
              <img src={require('./img/main/notice1.jpg').default} style={{ marginBottom: '16px', height: "26px", width: '200px' }} /></Row>
            <Row className='container-mobile div-align-center'><img src={require('./img/main/notice2.png').default} style={{ marginBottom: '16px', height: "60px", width: '180px' }} /></Row>
            <Row className='container-mobile div-align-center'><img src={require('./img/main/notice3.jpeg').default} style={{ marginBottom: '16px', height: "40px", width: '180px' }} /></Row>
          </Row>
        </Row>

        <Row className="container-mobile" style={{ marginBottom: '120px' }}>

          <h2 style={{ padding: "100px 0px 0px 0px", fontSize: '28px' }}><span style={{ fontWeight: '200' }}>자주 묻는</span>
            <span style={{ fontWeight: '700' }}> 질문</span></h2>
          <Row >
            <Button
              block
              className="shadow-none"
              onClick={e => this.setState({ btnStatus: !this.state.btnStatus })}
              style={{
                marginBottom: '0.6rem',
                padding: '16px 0px 16px 12px',
                backgroundColor: this.btnBgc(this.state.btnStatus),
                color: '#363636',
                border: '1px solid #e0e0e0',
                fontSize: '14px', textAlign: 'left'
              }}>
              <RiMessage3Line style={{ marginBottom: "4px" }} />   승마장 등록 방법은 무엇인가요?
            </Button>
            <Collapse isOpen={this.state.btnStatus}>
              <Card style={{ backgroundColor: '#fff', color: '#363636', border: '1px solid #fff', padding: '0px 0px 20px 10px', textAlign: 'left' }}>
                <CardBody style={{ fontSize: '12px' }}>
                  <RiCustomerService2Fill color='#12AFDC' /><br />카카오톡 플러스 친구 ‘말타’ 또는 고객센터 02-567-0543 으로 문의 주시면 협약 승마장 가입 안내 절차를 받으실 수 있습니다.
                </CardBody>
              </Card>
            </Collapse>

            <Row className="container-mobile" >
              <Button
                block
                className="shadow-none"
                onClick={e => this.setState({ btnStatus1: !this.state.btnStatus1 })}
                style={{
                  margin: '0px 10px 0px 10px',
                  padding: '16px 0px 16px 12px',
                  backgroundColor: this.btnBgc(this.state.btnStatus1),
                  color: '#363636',
                  border: '1px solid #e0e0e0',
                  fontSize: '14px', textAlign: 'left'
                }}>
                <RiMessage3Line style={{ marginBottom: "4px" }} />  매니저앱 로그인은 어떻게 하나요?
              </Button>
              <Collapse isOpen={this.state.btnStatus1}>
                <Card style={{ backgroundColor: '#fff', color: '#363636', border: '1px solid #fff', padding: '0px 0px 20px 10px', textAlign: 'left' }}>
                  <CardBody style={{ fontSize: '12px' }}>
                    <RiCustomerService2Fill color='#12AFDC' /><br />매니저앱의 경우 ‘말타’에서 직접 코치님들의 아이디를 만들어 드립니다.<br />
                    협약 승마장 등록 전 매니저앱을 직접 체험해보고 싶은 경우 카카토톡 플러스 친구 ‘말타’또는 고객센터 02-567-0543 으로 문의 부탁 드립니다.
                  </CardBody>
                </Card>
              </Collapse>
            </Row>
            <Row className="container-mobile">
              <Button
                block
                className="shadow-none"
                onClick={e => this.setState({ btnStatus2: !this.state.btnStatus2 })}
                style={{
                  margin: '10px 10px 0px 10px',
                  padding: '16px 0px 16px 12px',
                  backgroundColor: this.btnBgc(this.state.btnStatus),
                  color: '#363636',
                  border: '1px solid #e0e0e0',
                  fontSize: '14px', textAlign: 'left'
                }}>
                <RiMessage3Line style={{ marginBottom: "4px" }} />  예약은 어떻게 받는건가요?
              </Button>
              <Collapse isOpen={this.state.btnStatus2}>
                <Card style={{ backgroundColor: '#fff', color: '#363636', border: '1px solid #fff', padding: '0px 0px 20px 10px', textAlign: 'left' }}>
                  <CardBody style={{ fontSize: '12px' }}>
                    <RiCustomerService2Fill color='#12AFDC' /><br />1. 예약이 접수될 경우 매니저앱으로 예약 승인이 접수됩니다.<br />
                    2. 예약 스케줄을 확인하시고 회원의 예약을 승인 확정 및 거절을 눌러주세요.<br />
                    3. 예약 거절 시 거절 사유 및 추천시간을 추천해줄 수 있습니다.
                  </CardBody>
                </Card>
              </Collapse>
            </Row>
            <Row className="container-mobile">
              <Button
                block
                className="shadow-none"
                onClick={e => this.setState({ btnStatus3: !this.state.btnStatus3 })}
                style={{
                  margin: '10px 10px 0px 10px',
                  padding: '16px 0px 16px 12px',
                  backgroundColor: this.btnBgc(this.state.btnStatus),
                  color: '#363636',
                  border: '1px solid #e0e0e0',
                  fontSize: '14px', textAlign: 'left'
                }}>
                <RiMessage3Line style={{ marginBottom: "4px" }} />  계약해지는 언제든지 가능한가요?
              </Button>
              <Collapse isOpen={this.state.btnStatus3}>
                <Card style={{ backgroundColor: '#fff', color: '#363636', border: '1px solid #fff', padding: '0px 0px 20px 10px', textAlign: 'left' }}>
                  <CardBody style={{ fontSize: '12px' }}>
                    <RiCustomerService2Fill color='#12AFDC' /><br />네, 가능합니다. 다만 협약승마장에서 제외되며 따라서 신규회원 유입이 어려워 집니다.<br />
                    또한 고객들이 볼 수 있었던 승마장의 기본정보가 제공되지 않습니다.
                  </CardBody>
                </Card>
              </Collapse>
            </Row>
            <Row className="container-mobile">
              <Button
                block
                className="shadow-none"
                onClick={e => this.setState({ btnStatus4: !this.state.btnStatus4 })}
                style={{
                  margin: '10px 10px 0px 10px',
                  padding: '16px 0px 16px 12px',
                  backgroundColor: this.btnBgc(this.state.btnStatus),
                  color: '#363636',
                  border: '1px solid #e0e0e0',
                  fontSize: '14px', textAlign: 'left'
                }}>
                <RiMessage3Line style={{ marginBottom: "4px" }} />  승마장에 스케줄이나 요금에 맞춰<br />커스텀을 할 수 있나요?
              </Button>
              <Collapse isOpen={this.state.btnStatus4}>
                <Card style={{ backgroundColor: '#fff', color: '#363636', border: '1px solid #fff', padding: '0px 0px 20px 10px', textAlign: 'left' }}>
                  <CardBody style={{ fontSize: '12px' }}>
                    <RiCustomerService2Fill color='#12AFDC' /><br />네, 가능합니다. ‘말타’는 승마에 특화된 어플로써 모든 승마장에서 사용할 수 있도록 맞춤 제작되었습니다.<br />동절기, 하절기 운영시간, 멤버쉽, 쿠폰 요금제 등 편리하게 이용하실 수 있습니다.
                  </CardBody>
                </Card>
              </Collapse>
            </Row>
          </Row>
        </Row>
        <Row style={{ backgroundColor: "#12AFDC", width: '100%', margin: 0 }}>
          <Row className='container-mobile' >

            <h2 style={{ paddingTop: "40px", fontWeight: '700', color: "#fff", fontSize: '24px' }}>이제 '말타'로<br />승마를 시작해보세요!</h2>
            <p style={{ color: "#fff", fontSize: '12px' }}>보다 편리한 승마 서비스를 제공합니다.</p>
            <Row className='container-mobile' style={{ padding: 4 }}>
              <Button className="main-button1-mobile-app" style={{ padding: '5px' }}
                // href="https://apps.apple.com/app/id1532007829" Target="_blank">
                onClick={() => this.appstoreBtnClicked()}>
                <FaApple size="19" style={{ padding: "0px" }} />
                <span style={{ fontSize: '14px', marginTop: '20px' }}> APP STORE</span>
              </Button>
            </Row>
            <Row className='container-mobile' style={{ padding: 4 }}>

              <Button className="main-button2-mobile-app" style={{ padding: '5px' }}
                //  href="https://play.google.com/store/apps/details?id=com.timehive.marlta" Target="_blank">
                onClick={() => this.playStoreBtnClicked()}>
                <FaGooglePlay />
                <span style={{ fontSize: '14px' }}> PLAY STORE</span>
              </Button>
            </Row>
          </Row>
          <Col>
            <img style={{ height: '160px', marginTop: "20px", marginBottom: '30px' }} src={require('./img/main/download.png').default} />
          </Col>
        </Row>
        <Row className="phone-mobile div-align-center container-mobile" style={{ padding: 0, margin: 0 }}>
          <Row>
            <h2 style={{ fontWeight: "200", fontSize: '20px', marginTop: '50px' }}>더 궁금한 점이 있으신가요?</h2>
          </Row>
          <Row className="phone2-mobile div-align-center">
            <h2><span style={{ fontWeight: "300", fontSize: '22px' }}>말타 고객센터</span><br /><span style={{ fontWeight: "700" }}>02-567-0543</span></h2>
            <p style={{ fontSize: '16px', marginBottom: '40px' }}>운영시간 : 평일   AM 09:30 - PM 06:30</p>
          </Row>
        </Row>
        <FooterM />
      </>
    );
  }
}

export default Home2;