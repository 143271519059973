import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import '../App.css';

class Footer extends Component {
    render() {
        return (
            <Col className="footer" style={{
                padding: "1.4rem 0rem 0.2rem 0rem",
                backgroundColor: "#fafafa", minWidth: 1080
            }}>
                <Col className="footer-col">

                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}
                        href='https://blog.naver.com/luxspo'>
                        <img src={require('../img/main/blog_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}
                        href='https://www.youtube.com/channel/UCFfyX9Q7Gt1ZwHdNY8ZAY5w'>
                        <img src={require('../img/main/youtube_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}
                        href={'https://www.instagram.com/malta_luxspo/'}>
                        <img src={require('../img/main/instagram_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}
                        href='https://www.facebook.com/malta.luxspo'>
                        <img src={require('../img/main/facebook_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <h4>(주)럭스포 LUXSPO</h4>
                    <p >사업자등록번호 : 392-87-01758
                        | 대표이사: 김아라 | 사업자등록정보확인 | 주소 : 서울특별시 강남구 논현로 124길 21, B01호 2008(논현동)<br /> 대표전화 : 02-567-0543
                        | FAX : 070-4659-0969 | 이메일 : help@luxspo.com</p>
                    <p>Copyright © LUXSPO Corp. All Rights Reserved.</p>
                </Col>
            </Col>
        );
    }
}

export default Footer;