import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Footer from './Footer/Footer';



class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    toggle = () => {
        this.setState({modal: !this.state.modal})
    }
    agreeBtnClicked = () => {
      window.location.href = '/#/help'
    }
  
  
    render () {
        return (
        <>
                <Row style={{ backgroundColor: "#12AFDC", height: '280px', width: '100%', padding:0, margin:0 }}>
                    <Container>
                        <Col className="help-header" style={{ textAlign: 'left' }}>
                            <h2 style={{ margin: "100px 0px 40px 0px", fontSize: '46px', color: '#fff' }}><span style={{ fontWeight: '200' }}>말타</span>
                                <span style={{ fontWeight: '700' }}> 문의하기</span></h2>
                            <p style={{ fontSize: '18px', color: '#fff', marginTop: '-34px' }}>작성하신 내용을 바탕으로 친절히 답변드리겠습니다.</p>
                        </Col>
                    </Container>
                </Row>
              
            <Container style={{ maxWidth: "1200px", marginTop: "20px", marginBottom:"110px"}}>
                    <Col style={{ textAlign: "center" }}>
                        <h2 style={{ paddingTop: '90px', paddingBottom: '70px', lineHeight: "46px" }}>
                            <span style={{ fontWeight: '700', fontSize: '36px', color: '#12AFDC' }}>고객님의 소중한 의견에 신뢰할 수 있는<br />최선의 답변</span>
                            <span style={{ fontWeight: '200', fontSize: '36px' }}>을 드릴 수 있도록 노력하겠습니다.</span>
                        </h2>
                    </Col>
                <Col style={{ textAlign: "left", padding:'0px 20px 0px 20px', margin: 0}}>

                    <h2 style={{ margin: "40px 0px 50px 0px", fontSize: '30px' }}><span style={{ fontWeight: '200' }}>말타</span>
                    <span style={{ fontWeight: '700' }}> 이용약관</span></h2>

                        <Col style={{ marginBottom: "50px", padding: 0 }}>
                            <h4 style={{ fontWeight: '500', fontSize: '22px' }}>수집하는 개인정보 항목 및 수집방법</h4>
                            <p style={{ fontWeight: '300', fontSize: '18px', lineHeight: "28px" }}>말타 홈페이지는 서비스 이용을 위해 필요한 최소한의 개인정보만을 “문의하기” 게시판에서 이용자가 입력한 정보에 한해 수집합니다. 귀하가
                        말타 홈페이지의 서비스를 이용하기 위해서는 연락처를 입력하셔야 하며, 입력하신 정보는 서비스 제공에 사용됩니다.<br />
                        [수집항목] : 승마클럽 명(필수), 담당자 성함(필수), 담당자 연락처(필수)</p>
                        </Col>
                        <Col style={{ marginBottom: "50px", padding: 0 }}>
                            <h4 style={{ fontWeight: '500', fontSize: '22px' }}>[필수] 개인정보의 보유 및 이용기간</h4>
                            <p style={{ fontWeight: '300', fontSize: '18px', lineHeight: "28px" }}>말타 홈페이지는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 귀하의 개인정보를 즉시 파기합니다.<br />[보유기간] : 문의내용 처리 후 즉시 파기</p>
                        </Col>
                        <Col style={{ marginBottom: "50px", padding: 0 }}>
                            <h4 style={{ fontWeight: '500', fontSize: '22px' }}>[필수] 개인정보의 수집 및 이용목적</h4>
                            <p style={{ fontWeight: '300', fontSize: '18px', lineHeight: "28px" }}>말타 홈페이지는 귀하의 개인정보 수집에 대한 동의를 받고 있습니다. 귀하의 개인정보 수집과 관련하여 말타 홈페이지의 개인정보처리방침 또는 이용약관의 내용에 대해 『동의 및 문의등록』 버튼 또는 『동의 안 함』 버튼을 클릭할 수 있는 절차를 마련하고 있으며, 귀하가 『동의 및 문의등록』 버튼을 클릭하면 개인 정보 수집에 대해 동의한 것으로 봅니다. <br />[이용목적] : 문의내용 답변 목적</p>
                        </Col>
                    </Col>

                    <Col style={{ textAlign: "center" }}>
                        <h4 style={{ fontWeight: '500', fontSize: '20px', padding: '60px 0px 0px 0px' }}>
                        위 약관에 동의하십니까?</h4>
                    </Col>

                    <Row className='justify-content-md-center'>
                        <Col>
                                <Button style={{
                                    backgroundColor: '#12AFDC',
                                    color: '#fff',
                                    width: '240px',
                                    height: '60px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: '22px',
                                    fontWeight: '600',
                                    border: 'none',
                                    outline: 'none',
                                    lineHeight: '0.5px',
                                    boxShadow: 'none'
                                }} onClick={()=>this.agreeBtnClicked()}>동의 및 문의 등록</Button>
                            <Button style={{
                                backgroundColor: '#F8F9FA',
                                color: 'rgb(46, 46, 46)',
                                width: '240px',
                                height: '60px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                fontSize: '22px',
                                fontWeight: '600',
                                border: 'none',
                                outline: 'none',
                                lineHeight: '0.5px',
                                boxShadow: 'none'
                            }} onClick={()=>this.toggle()}>동의 안 함</Button>
                        </Col>
                        <Modal isOpen={this.state.modal} fade={true} toggle={this.toggle} aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <ModalBody style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '24px 0px 0px 24px',
                                fontSize: '18px', fontWeight: '500'
                            }}>
                                문의 등록을 하시려면 '동의 및 문의등록'을 눌러주세요.
                        </ModalBody>
                            <ModalFooter style={{ height: "70px", borderTopColor: "#fff" }}>
                                <Button style={{
                                    backgroundColor: '#12AFDC',
                                    color: '#fff',
                                    width: '100px',
                                    padding: 0,
                                    margin: 0,
                                    height: "46px",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: '600'
                                }} onClick={()=>this.toggle()}>닫기</Button>
                            </ModalFooter>
                        </Modal>
                    </Row>
                </Container>    
                    <Footer />
         </>

        );
    }
}

export default Terms;