
import React, { Component } from 'react';
import axios from 'axios'
import { Row, Col, Button } from 'reactstrap';
import { AddAlertRounded } from '@material-ui/icons';

class EventMain extends Component {
    constructor(props) {
        super(props);

        const { search } = this.props.location;
        const urlParams = new URLSearchParams(search)
        const club_name = urlParams.get('cn')
        // alert(this.props.match.params.userID+':'+this.props.match.params.clubID);
        // this.getIPAddress(linkParam);


        var decodedClubName = null;
        var userID = null;
        var clubID = null;
        // alert('0 : ' + club_name + ' - ' + this.props.match.params.userID + ' - ' + this.props.match.params.clubID);
        try {
            decodedClubName = decodeURIComponent(atob(club_name));
            userID = atob(this.props.match.params.userID)
            clubID = atob(this.props.match.params.clubID)
        } catch (error) {
        }
        // alert('123 + '+atob(this.props.match.params.userID));
        //  var userID = decodeURIComponent(atob(this.props.match.params.userID));
        //  var clubID = decodeURIComponent(atob(this.props.match.params.clubID));


        // alert(decodedClubName + ' - ' + userID + ' - ' + clubID);
        if (userID && clubID && decodedClubName) {
            this.state = {
                index: 0,
                userID: this.props.match.params.userID,
                clubID: this.props.match.params.clubID,
                clubName : club_name
            }
        } else {
            // alert('잘못된 접근입니다')
            this.state = {
                index: 0,
                userID: null,
                clubID: null
            }
        }
    }


    getIPAddress = (linkParam) => {

        var log_id = window.sessionStorage.getItem('lid');
        if (log_id == undefined) {
            axios.get('https://geolocation-db.com/json/').then(res => {
                // alert(linkParam + ' / ' + res.data.IPv4);
                this.postLinkIP(linkParam, res.data.IPv4);
            })
        }
    }

    getImageURL = () => {

        axios.get('http://3.38.226.155:8080/admin/homepage/question', {
            club_name: this.state.club_name,
            manager_name: this.state.manager_name,
            manager_number: this.state.phone_number,
            content: this.state.question
        }).then(res => {
            if (res.data.status == 1) {
                alert('문의가 등록되었습니다');
                this.moveToHome()
            } else {
                alert(res.data.message);
            }
        }).catch(error => {
            alert(error);
        });
    }

    moveToPriceReport = () => {
        window.location.href = '/#/reportprice?cid=' + this.state.clubID + '&uid=' + this.state.userID +'&cn='+this.state.clubName
    }

    render() {
        return (
            <div>
                {
                    this.state.userID ? (
                        <div class='event-back' style={{ height: '100vh', width: '100%', alignItems: 'center', display: 'flex' }}>
                            {/*
                    this.props.match.params.category && this.props.match.params.id && (
                        <div>
                            <img
                                src="https://w.namu.la/s/da961c860274c2b4eca4d4438160a7f742a6477f38df6135d685515596dfaac317b53eba513f61c8e4bc6e366041f17e151be3b768305e1a2801e6f5d11650a64c4025d40c4fb34bfa035dbe4c24a887af0898161e41169d501350eab8efa49a068f35ae0c5211a2041b54f0079bb1e6"
                                style={{width:'100%'}}
                            />

                        </div>
                    )
                    */}
                            <Row class='text-center' style={{ width: '100%', justifyContent: 'center', margin: 0 }}>
                                <img class='rounded-circle' src='https://bio.linkcdn.to/upload/2022060202/165413631500084306.png'
                                    style={{ width: 100, height: 100, padding: 0, marginBottom: 20 }} />
                                <h4 style={{ color: '#fff' }}><strong>말타</strong></h4>
                                <h6 style={{ color: '#fff' }}>승마의 모든 서비스를 하나로</h6>

                                <Row className='text-center event-row'>
                                    <Col xs='11' className='event-bar'>
                                        <Row>
                                            <Col style={{ maxWidth: '60px', padding: 0 }}>
                                                <img className='rounded-circle event-bar-logo' src='https://bio.linkcdn.to/upload/2022060202/165413631500084306.png'></img>
                                            </Col>
                                            <Col className='event-bar-btn-col'>
                                                <Button className='event-bar-btn' onClick={()=>this.moveToPriceReport()}>가격표 제보하기</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='text-center event-row'>
                                    <Col xs='11' className='event-bar'>
                                        <Row>
                                            <Col style={{ maxWidth: '60px', padding: 0 }}>
                                                <img className='rounded-circle event-bar-logo' src='https://bio.linkcdn.to/upload/2022060202/165413631500084306.png'></img>
                                            </Col>
                                            <Col className='event-bar-btn-col'>
                                                <Button className='event-bar-btn' onClick={()=>this.moveToPriceReport()}>승마장 관리자인가요?</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='text-center event-row'>
                                    <Col xs='11' className='event-bar'>
                                        <Row>
                                            <Col style={{ maxWidth: '60px', padding: 0 }}>
                                                <img className='rounded-circle event-bar-logo' src='https://bio.linkcdn.to/upload/2022060202/165413631500084306.png'></img>
                                            </Col>
                                            <Col className='event-bar-btn-col'>
                                                <Button className='event-bar-btn' href='https://www.instagram.com/malta_luxspo/'>말타 인스타그램</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='text-center event-row'>
                                    <Col xs='11' className='event-bar'>
                                        <Row>
                                            <Col style={{ maxWidth: '60px', padding: 0 }}>
                                                <img className='rounded-circle event-bar-logo' src='https://bio.linkcdn.to/upload/2022060202/165413631500084306.png'></img>
                                            </Col>
                                            <Col className='event-bar-btn-col'>
                                                <Button className='event-bar-btn' href='https://blog.naver.com/luxspo'>말타 블로그</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Row>
                        </div>
                    ) : (
                        <div>잘못된 접근입니다.</div>
                    )
                }
            </div>

        );
    }
}


export default EventMain;