import React, { Component } from 'react';
import { Col, Button } from 'reactstrap';
import '../App.css';

class FooterM extends Component {
    render() {
        return (
            <Col className="footer" style={{padding: "1.4rem 0rem 0.2rem 0rem", backgroundColor: "#fafafa"}}>
                <Col >
                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}>
                        <img src={require('../img/main/instagram_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}>
                        <img src={require('../img/main/blog_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <Button style={{ width: 40, height: 40, marginTop: 0, padding: 0, backgroundColor: '#ffff' }}>
                        <img src={require('../img/main/facebook_logo.png').default} style={{ width: 40, height: 40, margin: 0 }} />
                    </Button>
                    <h4 style={{fontSize:'12px'}}>(주)럭스포 LUXSPO</h4>
                    <p style={{fontSize:'10px'}}>사업자등록번호 : 392-87-01758
                    | 대표이사 : 김아라 | 사업자등록정보확인 | 주소 : 서울특별시 강남구 논현로 124길 21, B01호 2008(논현동)<br/> 대표전화 : 02-567-0543
                       | FAX : 070-4659-0969 | 이메일 : help@luxspo.com</p>
                       <p style={{fontSize:'10px'}}>Copyright © LUXSPO Corp. All Rights Reserved.</p>
                </Col>
            </Col>
        );
    }
}

export default FooterM;