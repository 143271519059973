import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Input, FormText, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FooterM from './Footer/FooterM';
import axios from 'axios'



class MobileHelp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      club_name: "",
      manager_name: "",
      phone_number: "",
      question: ""
    }
  }
  moveToHome = () => {
    window.location.href = '/'
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal })
  }

  sendQuestion = () => {
    // alert(JSON.stringify(this.state));
    var errorMsg = null;
    if (this.state.club_name.length < 3) {
      errorMsg = '승마장 이름을 제대로 입력해주세요'
    } else if (this.state.manager_name.length < 2) {
      errorMsg = '관리자 성함을 제대로 입력해주세요'
    } else if (this.state.phone_number.length < 9) {
      errorMsg = '연락처를 제대로 입력해주세요'
    } else if (this.state.question.length < 5) {
      errorMsg = '문의내용을 제대로 입력해주세요'
    }
    if (errorMsg && errorMsg.length > 0) {
      alert(errorMsg);
    } else {

      axios.post('http://3.38.226.155:8080/admin/homepage/question', {
        club_name: this.state.club_name,
        manager_name: this.state.manager_name,
        manager_number: this.state.phone_number,
        content: this.state.question
      }).then(res => {
        if (res.data.status == 1) {
          alert('문의가 등록되었습니다');
          this.moveToHome();          
        } else {
          alert(res.data.message);
        }
      }).catch(error => {
        alert(error);
      });
    }
  }
  render() {
    return (
      <>
        <Row style={{ backgroundColor: "#12AFDC", width: '100%', padding: 0, margin: 0 }}>
          <Row className='container-mobile'>
            <Row className="help-header" style={{ textAlign: 'left' }}>
              <h2 style={{ margin: "40px 0px 40px 10px", fontSize: '30px', color: '#fff' }}><span style={{ fontWeight: '200' }}>말타</span>
                <span style={{ fontWeight: '700' }}> 문의하기</span></h2>
              <p style={{ fontSize: '14px', color: '#fff', marginTop: '-34px', marginBottom: "34px", marginLeft: '10px' }}>작성하신 내용을 바탕으로 친절히<br />답변드리겠습니다.</p>
            </Row>
          </Row>
        </Row>
        <Row className='container-mobile div-align-center' style={{ marginBottom: "70px" }}>
          <Row style={{ padding: '40px 0px 0px 0px' }}>
            <h2 style={{ fontSize: '26px', padding: '30px 0px 30px 0px' }}>
              <span style={{ fontWeight: '700' }}> 문의하기</span></h2>
            <Form>
              <FormGroup row>
                <Label for="exampleclub" xs={3} style={{ fontSize: '14px' }}>승마클럽 명</Label>
                <Col xs={9}>
                  <Input type="club" name="club" id="exampleclub"  onChange={(e)=>this.setState({club_name:e.target.value})}/></Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplename" xs={3} style={{ fontSize: '14px' }}>담당자 성함</Label>
                <Col xs={9}>
                  <Input type="name" name="name" id="examplename"  onChange={(e)=>this.setState({manager_name:e.target.value})}/></Col>
              </FormGroup>
              <FormGroup row>
                <Label for="examplename" xs={3} style={{ fontSize: '14px' }}>담당자 번호</Label>
                <Col xs={9}>
                  <Input type="phone" name="phone" id="examplephone"  onChange={(e)=>this.setState({phone_number:e.target.value})}/></Col>
              </FormGroup>
              <FormGroup row>
                <Label for="exampleText" xs={3} style={{ fontSize: '14px' }}>문의내용</Label>
                <Col xs={9}>
                  <Input className="helptext" type="textarea" rows="8" name="text" id="exampleText"  onChange={(e)=>this.setState({question:e.target.value})}/></Col>
                <FormText color="muted" style={{ textAlign: 'center', marginTop: '20px', fontSize: '10px', marginBottom: '20px' }}>
                  원활한 상담을 위해 문의할 내용을 자세히 작성해주세요.<br />
                  작성 내용을 바탕으로 친절히 답변드리겠습니다.
                </FormText>
              </FormGroup>
            </Form>
          </Row>
          <Row className='container-mobile div-align-center'>
            <Button style={{
              backgroundColor: '#12AFDC',
              color: '#fff',
              width: '150px',
              height: '42px',
              fontSize: '14px',
              fontWeight: '600',
              border: 'none',
              outline: 'none',
              lineHeight: '0.5px',
              boxShadow: 'none',
              padding: 0,
              margin: '10px'
            }} onClick={() => this.sendQuestion()}>문의 등록</Button></Row>
          <Row className='container-mobile div-align-center'>
            <Button style={{
              backgroundColor: '#F8F9FA',
              color: 'rgb(46, 46, 46)',
              width: '150px',
              height: '42px',
              fontSize: '14px',
              fontWeight: '600',
              border: 'none',
              outline: 'none',
              lineHeight: '0.5px',
              boxShadow: 'none',
              padding: 0,
              margin: '4px 10px 10px 10px'
            }} onClick={() => this.moveToHome()}>취소</Button></Row>

        

        </Row>

        <FooterM />

      </>
    );
  }
}

export default MobileHelp;