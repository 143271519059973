
import React, { Component } from 'react';
import axios from 'axios'
import { Row, Col, Button, Input, FormGroup, Label } from 'reactstrap';
import { FilledInput } from '@material-ui/core';
import moment from 'moment';

class ReportPrice extends Component {
    constructor(props) {
        super(props);

        const { search } = this.props.location;
        const urlParams = new URLSearchParams(search)
        const club_name = urlParams.get('cn')
        const uid = urlParams.get('uid')
        const cid = urlParams.get('cid')
        // alert(this.props.match.params.category+':'+this.props.match.params.id);
        // this.getIPAddress(linkParam);


        var decodedClubName = null;
        var userID = null;
        var clubID = null;
        // alert('0 : ' + club_name + ' - ' + this.props.match.params.userID + ' - ' + this.props.match.params.clubID);
        try {
            decodedClubName = decodeURIComponent(atob(club_name));
            userID = atob(uid)
            clubID = atob(cid)
        } catch (error) {
        }

        var reportInfo = {
            user_id: userID,
            public_club_id: clubID,
            last_date: new Date(),
            report_message: '',
            report_image_urls: []
        }
        if (userID && clubID && decodedClubName) {
            // alert(userID+' '+clubID+' '+decodedClubName);
            this.state = {
                index: 0,
                userID: userID,
                clubID: clubID,
                clubName: decodedClubName,
                reportInfo: reportInfo
            }
        } else {
            // alert('잘못된 접근입니다')아쟁
            this.state = {
                index: 0,
                userID: null,
                clubID: null,
                reportInfo: reportInfo
            }
        }

    }
    imageChanged = (e) => {

        if (e.target.files && e.target.files.length > 0) {

            const me = this;
            var formData = new FormData();
            formData.append('user_id', this.state.userID);
            formData.append('folderPath', "report/content");
            formData.append('file', e.target.files[0]);

            this.uploadImage(formData);
        }
    }

    uploadImage = (formData) => {
        axios.post("https://malta.timehive.kr/v3/user/s3/upload", formData)
            .then(res => {
                if (res.data.status == 1) {
                    if (res.data.data.location && res.data.data.location.length > 0) {
                        var reportInfo = this.state.reportInfo;
                        var report_image_urls = reportInfo.report_image_urls;   
                        report_image_urls.push(res.data.data.location);                     
                        reportInfo.report_image_urls = report_image_urls;
                        this.setState({
                            reportInfo: reportInfo
                        })
                    }
                } else {
                    alert('Error');
                }
            }).catch(error => {
                alert('Error LOG : ' + error);
            });
    }
    inputChanged = (value, key) => {
        var reportInfo = this.state.reportInfo;
        reportInfo[key] = value;
        this.setState({
            reportInfo: reportInfo
        })
    }
    checkboxChanged = (value, index) => {
        switch (index) {
            case 0:
                this.setState({
                    isContact: value
                })
                break;

            case 1:
                this.setState({
                    isProgram: value
                })
                break;

            case 2:
                this.setState({
                    isPrice: value
                })
                break;

            default:
                break;
        }
    }

    reportPriceBtnClicked = () => {
        var msg = '';
        if(this.state.isContact){
            msg = msg + '[연락처, 주소 및 운영시간] '
        }
        if(this.state.isProgram){
            msg = msg + '[프로그램 및 쿠폰 이름] '
        }
        if(this.state.isPrice){
            msg = msg + '[프로그램 및 쿠폰 가격] '
        }
        var reportInfo = JSON.parse(JSON.stringify(this.state.reportInfo));
        msg = msg + reportInfo.report_message;
        reportInfo.report_message = msg;

        var date = reportInfo.last_date;
        var dateStr = moment(date).format('YYYY-MM-DD')
        reportInfo.last_date = dateStr;

        if(msg.length==0){
            alert('제보할 정보를 선택 혹은 입력해주세요')
        }else{            
            // alert(JSON.stringify(reportInfo))
            axios.post('http://3.38.226.155:8080/v3/user/club/price/report', reportInfo)
            .then(res => {
                // alert('로그 : ' + JSON.stringify(res.data));
                if (res.data.status == 1) {
                    window.location.href = '/#/event/success'
                    // alert('정상적으로 제보 되었습니다. 감사합니다.');
                } else {
                    alert(res.data.message);
                }
            }).catch(error => {
                alert(error);
            });
        }
    }

    render() {
        return (
            <>
                {
                    this.state.userID ? (
                        <div class='event-back2' style={{width: '100%', alignItems: 'center' }}>
                            <Row class='text-center' style={{ width: '100%', justifyContent: 'center' }}>
                                <Col xs='11' className='text-left' style={{ maxWidth: '700px', backgroundColor: '#fff', borderRadius: '10px', padding: 20, margin: 10 }}>
                                    <h3 style={{ marginTop: 5 }}>[{this.state.clubName}]</h3><h4>승마장 가격표 제보하기</h4>
                                    <div style={{ fontSize: 15 }}>안녕하세요! 승마 종합 플랫폼 말타입니다:)<br />
                                        {this.state.clubName} 승마장의 등록된 정보가 방문 내용과 다르셨나요?<br />
                                        미협약 승마장의 등록정보는 인터넷 또는 말타 회원분들의 제보로 업로드되고 있습니다.<br />
                                        사진 또는 다른 부분에 대한 설명을 제보해주시면 내용을 반영하여 수정하겠습니다.<br />
                                        여러분들의 소중한 제보 감사합니다♡</div>
                                </Col>
                            </Row>
                            <Row class='text-center' style={{ width: '100%', justifyContent: 'center' }}>
                                <Col xs='11' className='text-left' style={{ maxWidth: '700px', backgroundColor: '#fff', borderRadius: '10px', padding: 20, margin: 10 }}>
                                    <h5 style={{ marginTop: 5 }}>승마장 방문일</h5>
                                    <div style={{ fontSize: 15 }}>
                                        <Input type='date' onChange={(e) => this.inputChanged(e.target.value, 'last_date')} datatype='date'></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row class='text-center' style={{ width: '100%', justifyContent: 'center' }}>
                                <Col xs='11' className='text-left' style={{ maxWidth: '700px', backgroundColor: '#fff', borderRadius: '10px', padding: 20, margin: 10 }}>
                                    <h5 style={{ marginTop: 5, marginBottom: 15 }}>어떤 정보가 다르셨나요?</h5>

                                    <FormGroup check style={{ marginBottom: 10 }}>
                                        <Label check>
                                            <Input type="checkbox" name="radio2" onChange={(e) => this.checkboxChanged(e.target.checked, 0)} />{' '}
                                            연락처, 주소 및 운영시간
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check style={{ marginBottom: 10 }}>
                                        <Label check>
                                            <Input type="checkbox" name="radio0" onChange={(e) => this.checkboxChanged(e.target.checked, 1)} />{' '}
                                            프로그램 및 쿠폰 이름 / 설명
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check style={{ marginBottom: 15 }}>
                                        <Label check>
                                            <Input type="checkbox" name="radio1" onChange={(e) => this.checkboxChanged(e.target.checked, 1)} />{' '}
                                            프로그램 및 쿠폰 가격
                                        </Label>
                                    </FormGroup>

                                    <Label check>
                                        정보 입력
                                    </Label>
                                    <div style={{ fontSize: 15 }}>
                                        <Input type='text' onChange={(e) => this.inputChanged(e.target.value, 'report_message')} placeholder='정보 입력'></Input>
                                    </div>
                                </Col>
                            </Row>

                            <Row class='text-center' style={{ width: '100%', justifyContent: 'center' }}>
                                <Col xs='11' className='text-left' style={{ maxWidth: '700px', backgroundColor: '#fff', borderRadius: '10px', padding: 20, margin: 10 }}>
                                    <h5 style={{ marginTop: 5 }}>요금표 사진 첨부가 가능하시면 업로드 부탁드립니다.</h5>
                                    <Row style={{ marginBottom: 10 }}>
                                        {
                                            this.state.reportInfo.report_image_urls.map((item, index) => (
                                                <Col xs='3' style={{ padding: 2, minHeight: '100%' }}>
                                                    <Col className='text-center' style={{ backgroundColor: '#eee', padding: 2 }}>
                                                        <img src={item} style={{ border: 'solid 1px #ccc', maxWidth: '100%' }} />
                                                    </Col>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    <div style={{ fontSize: 15 }}>
                                        <Col xs='11' className='text-left' style={{ padding: '0px' }}>
                                            <label for='input-file2' style={{ cursor: 'pointer', marginLeft: 10, backgroundColor: '#78a', padding: '8px 20px', borderRadius: 10, color: '#fff' }}>사진 업로드</label>
                                            <Input type='file' color='info' id='input-file2' onChange={(e) => this.imageChanged(e)} accept="image/*" style={{ display: 'none' }}></Input>

                                        </Col>
                                    </div>
                                </Col>

                            </Row>
                            <Row class='text-center' style={{ width: '100%', justifyContent: 'center' }}>
                                <Button style={{ width: '400px', backgroundColor: '#259', color: '#fff' }} onClick={() => this.reportPriceBtnClicked()}>제보하기</Button>
                            </Row>
                        </div>
                    ) : (
                        <div>잘못된 접근입니다.</div>
                    )
                }

            </>
        )
    }
}

export default ReportPrice