import React, { Component } from "react";
import Slider from "react-slick";
import { Button, Container } from 'reactstrap';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}
export default class SimpleSlider extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
    page:0
  };

  slideOnChanged = (index) => {
    this.setState({
      page:index
    })
  }

  buttonClicked = (index) => {
    this.slider.slickGoTo(index);
    this.setState({
      page:index
    })
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: this.slideOnChanged,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      arrows: true
    };
    return (
      <div>
        <h2 style={{ fontWeight: '700',fontSize:'36px' }}>회원용 앱 서비스</h2>
        <p style={{ fontSize:'20px' }}>말타 이용 시 유용한 점을 소개합니다</p>
        
                {  this.state.page == 0 && (
                        <div className="slick-button">
                            <Button className="slick-button0" style={{ backgroundColor: "#12AFDC", color: "#fff",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(0)}>다양한 정보</Button>
                            <Button className="slick-button1" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(1)}>간편한 예약</Button>
                            <Button className="slick-button2" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(2)}>내역 리스트</Button>
                        </div>
                    )
                }
                {  this.state.page == 1 && (
                        <div className="slick-button">
                            <Button className="slick-button0" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(0)}>다양한 정보</Button>
                            <Button className="slick-button1" style={{ backgroundColor: "#12AFDC", color: "#fff",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(1)}>간편한 예약</Button>
                            <Button className="slick-button2" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(2)}>내역 리스트</Button>
                        </div>
                    )
                }
                {  this.state.page == 2 && (
                        <div className="slick-button">
                            <Button className="slick-button0" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(0)}>다양한 정보</Button>
                            <Button className="slick-button1" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(1)}>간편한 예약</Button>
                            <Button className="slick-button2" style={{ backgroundColor: "#12AFDC", color: "#fff",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(2)}>내역 리스트</Button>
                        </div>
                    )
                }

        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <div>
              <img className="service-img1" style={{height:"420px", width:"420px"}} src={require("./img/main/0001.png").default}/>
            <h2 style={{paddingTop:'50px',fontWeight:'700'}}>승마장 별 다양한 정보</h2>
            <p>말타는 승마장 별 강사진 및 마필을 볼 수 있으며,<br/>
            프로그램 별 정확한 가격 확인이 가능</p>
          </div>

          <div>
          <img className="service-img2" style={{height:"420px", width:"216px"}} src={require("./img/main/0002.png").default} />
            <h2 style={{paddingTop:'50px',fontWeight:'700'}}>터치 몇 번으로 예약 완료</h2>
            <p>번거로운 전화 및 메세지가 아니더라도 예약 날짜와<br/>
            시간, 기승 인원 등을 터치 몇 번으로 예약 완료</p>
          </div>
          
          <div>
          <img className="service-img3" style={{height:"420px", width:"216px"}} src={require("./img/main/0003.png").default} />
            <h2 style={{paddingTop:'50px',fontWeight:'700'}}>과거 강습 내역 확인</h2>
            <p>현재까지 받았던 강습에 대한 정보 확인이 가능하며<br/>
            담당 코치께서 해당 수업 내용 및 피드백 평가 제공</p>
          </div>
          
        </Slider>
      </div>
    );
  }
}