import React from 'react'
import Navbarmenu from './components/Navbarmenu';
import { BrowserView, MobileView } from 'react-device-detect';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';


// main
import Home from './Home';
import Home2 from './Home2';
import Service from './Service';
import Terms from './Terms';
import Help from './Help';

// mobile
import MobileHome from './MobileHome';
import MobileHome2 from './MobileHome2';
import MobileService from './MobileService';
import MobileTerms from './MobileTerms';
import MobileHelp from './MobileHelp';
import FooterM from './Footer/FooterM';


const TheLayout = ({ authenticated, component: Component, render, ...rest }) => {

    return (
        <div>
            <BrowserView>
                <Navbarmenu />
                <Switch>
                    {/* <Route exact path='/event' component={EventMain} /> */}
                    <Route exact path='/service' component={Service} />
                    <Route exact path='/terms' component={Terms} />
                    <Route exact path='/help' component={Help} />
                    <Route exact path='/' component={Home} />
                    <Route component={() => <Redirect to='/' />} />
                </Switch>
            </BrowserView>

            <MobileView>
                <Navbarmenu />
                <Switch>
                    <Route exact path='/service' component={MobileService} />
                    <Route exact path='/terms' component={MobileTerms} />
                    <Route exact path='/help' component={MobileHelp} />
                    <Route exact path='/' component={MobileHome} />
                    <Route component={() => <Redirect to='/' />} />
                </Switch>
            </MobileView>
        </div>
    )
}

export default TheLayout
