import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import SimpleSlider2 from './SimpleSlider2';
import { Button, Row, Col } from 'reactstrap';
import Footer from './Footer/Footer';




class Service extends Component {

  agreeBtnClicked = () => {
    window.location.href = '/#/terms'
  }

  render () {
  return (
    <>
    <Container>
      <Col className="service-introduction">
        <Row className="service-title">

          <h2 style={{ paddingTop: '120px',paddingBottom:'70px' }}>
            <span style={{ fontWeight: '700', fontSize: '42px', color: '#12AFDC' }}>프리미엄 요금제</span><br />
            <span style={{ fontWeight: '200', fontSize: '42px' }}>어떻게 다를까요?</span>
          </h2>

        </Row>

        <Row style={{ justifyContent: 'center', alignItems: 'center', marginLeft: '90px', marginBottom: '80px' }}>
          <Col xs={{ size: 6, order: 0, offset: 0 }} className="service-img1">
            <img style={{ margin: "30px 0px 0px 0px", width: '500px' }} src={require('./img/main/service-1_large.png').default} />
          </Col>

          <Col xs={{ size: 5, order: 0, offset: 0 }} className="service-text1" style={{ textAlign: 'left', margin: '80px 40px 0px 30px' }}>
            <h2 style={{ fontWeight: '700', fontSize: '30px', color: '#12AFDC' }}>모든 회원 관리를 앱 하나로!</h2>
            <p>기존 회원부터 신규 회원까지<br />예약과 쿠폰 관리를 스마트하게<br />관리할 수 있습니다.</p>
          </Col>
        </Row>

        <Row style={{
          justifyContent: 'center', alignItems: 'center',
          marginBottom: '80px'
        }}>

          <Col xs={{ size: 4, order: 0, offset: 1 }} className="service-text2" style={{ textAlign: 'left', paddingLeft: '40px' }}>

            <h2 style={{ fontWeight: '700', fontSize: '30px', color: '#12AFDC', marginTop: '80px' }}>다양한 클럽 일정 관리</h2>
            <p>말타 앱에 등록된 일정 외에도<br />
                            직접 타이틀을 입력하여<br />
                              더욱 정확한 일정 관리가 가능합니다.</p>
          </Col>

          <Col xs={{ size: 7, order: 0, offset: 0 }} className="service-img2" style={{ textAlign: 'left' }}>
            <img style={{ margin: "30px 0px 0px 0px", width: '600px' }} src={require('./img/main/service-2_large.png').default} />
          </Col>

        </Row>


        <Row style={{
          justifyContent: 'center', alignItems: 'center',
          marginLeft: '60px', marginBottom: '90px'
        }}>
          <Col xs={{ size: 7, order: 0, offset: 0 }} className="service-img3">
            <img style={{ margin: "30px 0px 0px 0px", width: '650px' }} src={require('./img/main/service-3_large.png').default} />
          </Col>
          <Col xs={{ size: 4, order: 0, offset: 1 }} className="service-text3" style={{ textAlign: 'left', margin: '0px 20px 0px 50px' }}>
            <h2 style={{ fontWeight: '700', fontSize: '30px', color: '#12AFDC' }}>앱과 PC의 연동</h2>
            <p>말타 앱의 관리 기능을<br />
                    PC 웹 페이지로 더욱 편리하고<br />
                    상세하게 만나보세요!</p>
          </Col>
        </Row>

        <Col style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>

          <Col className="service-img4">
            <img style={{ margin: '30px 0px 0px 0px', width: '1000px' }} src={require('./img/main/service-4_large.png').default} />
          </Col>
        </Col>


        <Col className="service-text4" style={{ textAlign: 'left', margin: '70px 0px 0px 20px' }}>
          <h3 style={{ fontSize: '20px' }}>* 모든 요금제의 승마체험/일일기승 프로그램의 10% 수수료가 부과됩니다.</h3>
          <p style={{ fontSize: '16px', color: '#757575',lineHeight:'26px' }}>ㆍ예 약 관 리 : 베이직 요금제는 일일체험/일일기승 한정 예약의 확인 및 승인이 가능합니다.<br />
                                        ㆍ회 원 관 리 : 승마클럽의 기존 회원까지 앱으로 관리할 수 있는 기능입니다.<br />
              ㆍ정           산 : 총 결제금액, 판매 수량 등 운영에 필요한 수치를 제공하는 기능입니다.<br />
              ㆍ마 필 관 리 : 마필별 장제, 구충 등의 건강/운동 내용을 기록할 수 있는 기능입니다.<br />
               ㆍ코칭 / 레슨 : 수업평가 기능을 통해 회원별/예약별 레슨진도를 확인할 수 있는 기능입니다.<br />
                 ㆍ일 정 관 리 : 베이직 요금제는 말타 앱에서 제공되는 일정만 등록 가능하며, 프리미엄 요금제는 수기로 자유로운 입력이 가능합니다.<br />
                 ㆍ쿠 폰 관 리 : 말타 앱에 회원의 쿠폰을 등록하여 유효기간, 사용 등을 자동으로 관리하는 기능입니다.</p>
        </Col>
        <Col className="service-button" style={{ textAlign: 'center', margin:'30px 0px 110px 0px'}}>
              <Button style={{
                backgroundColor: '#12AFDC',
                color: '#fff',
                width: '240px',
                height: '60px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '22px',
                fontWeight: '600',
                border: 'none',
                outline: 'none',
                lineHeight: '0.5px',
                boxShadow: 'none'
              }}onClick={()=>this.agreeBtnClicked()}>파트너스 문의</Button>
        </Col>
      </Col>
    </Container>
    <Footer />
    </>
    );
  }
}

export default Service;