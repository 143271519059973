import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { FaGooglePlay, FaApple } from "react-icons/fa";
import MobileHome2 from './MobileHome2';
import SimpleSliderM from './SimpleSliderM';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FooterM from './Footer/FooterM';
import './MobileStyle.css';
import axios from 'axios'



import background from "./img/main/header-background.jpg";
import { osVersion, osName, browserName, browserVersion, mobileVendor, mobileModel, isMobileOnly, isTablet, isBrowser, isAndroid, isIOS } from 'react-device-detect';

class MobileHome extends Component {
    constructor(props) {
        super(props);

        const { search } = this.props.location;
        const urlParams = new URLSearchParams(search)
        const linkParam = urlParams.get('link_source')
        // alert(linkParam);
        this.getIPAddress(linkParam);

        this.state = {
            index: 0
        }
    }

    movetoService = () => {
        window.location.href = '/#/service'
    }

    moveToTerms = () => {
        window.location.href = '/#/terms'
    }


    getIPAddress = (linkParam) => {

        var log_id = window.sessionStorage.getItem('lid');
        if (log_id == undefined) {
            axios.get('https://geolocation-db.com/json/').then(res => {
                // alert(linkParam + ' / ' + res.data.IPv4);
                this.postLinkIP(linkParam, res.data.IPv4);
            })
        }
    }

    postLinkIP = (linkParam, ipAddress) => {
        var param = {};

        if (linkParam) {
            param.link_source = linkParam;
        }
        if (ipAddress) {
            param.ip_address = ipAddress;
        }
        if (osVersion) {
            param.os_version = osVersion;
        }
        if (osName) {
            param.os_name = osName;
        }
        if (browserName) {
            param.browser_name = browserName;
        }
        if (browserVersion) {
            param.browser_version = browserVersion;
        }
        if (mobileVendor) {
            param.mobile_vendor = mobileVendor;
        }
        if (mobileModel) {
            param.mobile_model = mobileModel;
        }
        if (isMobileOnly) {
            param.is_mobile = isMobileOnly;
        }
        if (isTablet) {
            param.is_tablet = isTablet;
        }
        if (isBrowser) {
            param.is_browser = isBrowser;
        }
        if (isAndroid) {
            param.is_android = isAndroid;
        }
        if (isIOS) {
            param.is_ios = isIOS;
        }

        // alert(JSON.stringify(param));

        axios.post('http://3.38.226.155:8080/admin/homepage/logs', param).then(res => {
            if (res.data.status == 1) {
                window.sessionStorage.setItem('lid', res.data.data.log_id);
            } else {
                // alert(res.data.message);
            }
        }).catch(error => {
            // alert(error);
        });
    }


    appstoreBtnClicked = () => {
        this.logClick(1, 0);
        window.open('https://apps.apple.com/app/id1532007829', '_blank').focus();
    }

    playStoreBtnClicked = () => {
        this.logClick(0, 1);
        window.open('https://play.google.com/store/apps/details?id=com.timehive.marlta', '_blank').focus();
    }

    logClick = (as, ps) => {

        var log_id = window.sessionStorage.getItem('lid');
        // alert('GET LOG ID = '+log_id);
        if (log_id > 0) {
            var param = {}
            if (as > 0) { param.click_app_store = 1; }
            if (ps > 0) { param.click_play_store = 1; }
            axios.post('http://3.38.226.155:8080/admin/homepage/logs/click', {
                log_id: log_id,
                param: param
            }).then(res => {
                if (res.data.status == 1) {
                    // alert('로그 : ' + JSON.stringify(res.data.data.log_id));
                } else {
                    // alert(res.data.message);
                }
            }).catch(error => {
                // alert(error);
            });
        }
    }



    render() {
        return (
            <Row style={{ margin: 0 }}>
                <Row style={{ backgroundImage: `url(${background})`, width: '100%', backgroundSize: 'cover', padding: 5, margin: 0 }}>
                    <Row className='container-mobile'>
                        <Row className="main-mobile">
                            <h6 style={{ fontSize: "14px", marginTop: '20px' }}>기승 예약, 스케쥴 관리 플랫폼</h6>
                            <h1 className="main-mobile-h1">힐링의 시작,<br />말타</h1>
                            <p style={{ fontSize: "14px" }}>말타와 함께 승마장의<br />영업효과를 증명해보세요!</p>
                        </Row>
                        <Row className="main-mobile" style={{ marginBottom: '20px' }}>
                            <Button onClick={() => this.movetoService()} className="main-button1-mobile">서비스 소개</Button>
                            <Button onClick={() => this.moveToTerms()} className="main-button1-mobile">파트너스 문의</Button>
                        </Row>
                    </Row>
                </Row>
                <Row className='container-mobile'>
                    {
                        this.state.index == 0 ? (
                            <Row className="main-btn-mobile">
                                <Button className="main-btn1-mobile" onClick={e => this.setState({ index: 0 })}>회원 앱 기능보기</Button>
                                <Button className="main-btn2-mobile" onClick={e => this.setState({ index: 1 })}>코치 앱 기능보기</Button>
                            </Row>
                        ) : (
                            <Row className="main-btn-mobile" >
                                <Button className="main-btn2-mobile" onClick={e => this.setState({ index: 0 })}>회원 앱 기능보기</Button>
                                <Button className="main-btn1-mobile" onClick={e => this.setState({ index: 1 })}>코치 앱 기능보기</Button>
                            </Row>
                        )
                    }
                </Row>
                {
                    this.state.index == 0 ? (
                        <>
                            <Row className="justify-content-md-center" style={{ margin: "90px 0px 20px 0px" }}>
                                <h2>
                                    <span style={{ fontWeight: '200', fontSize: '22px' }}>편리하고 빠르게</span><br />
                                    <span style={{ fontWeight: '700', fontSize: '22px' }}>기승예약</span>
                                    <span style={{ fontWeight: '200', fontSize: '22px' }}>을 할 수 있습니다</span>
                                </h2>
                            </Row>
                            <Row className="main-mobile-app" style={{ marginBottom: "90px" }}>
                                <Row xs={{ size: 4, order: 0, offset: 0 }}>
                                    <img className="mockup-img-mobile" src={require('./img/main/member-mockup.png').default} />
                                </Row>
                                <Row className="app-service-mobile">
                                    <h2 className="app-service2-mobile">기승자를 위한 맞춤 추천</h2>
                                    <p className="app-service3-mobile">
                                        말타는 가까운 위치의 승마장을 상단에 띄워<br />기승자가 보다 편리하게 실시간 예약이<br />가능한 기반의 서비스입니다.</p>
                                </Row>
                            </Row>
                            <Row style={{ padding: 0, margin: 0 }}>
                                <Col style={{ backgroundColor: "#12AFDC", width: '100%', padding: 0 }}>
                                    <Col className="main-mobile">
                                        <Col style={{ textAlign: 'center' }}>
                                            <h2 style={{ padding: '22px 0px 18px 0px', fontWeight: '300', color: '#fff', fontSize: '24px' }} >말타는<br />
                                                <span style={{ fontWeight: '700', color: '#fff', fontSize: '24px' }} >이런 분들께<br />추천 드립니다!</span></h2>
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "20px", margin: 0 }}>
                                <Col className="align-middle" >
                                    <Col className="m01-1-mobile">
                                        <img style={{ height: '60px', float: "left" }} src={require('./img/main/circle1.png').default} />
                                        <h4 style={{ marginTop: '10px', fontSize: '14px', lineHeight: '20px' }}>전화 상담 및 예약이<br />번거로우신 분</h4>
                                    </Col>
                                    <Col className="m02-1-mobile">
                                        <img style={{ height: '60px', float: "left", }} src={require('./img/main/circle2.png').default} />
                                        <h5 style={{ marginTop: '10px', fontSize: '14px', lineHeight: '20px' }}>가까운 승마장을<br />찾고 싶으신 분</h5>
                                    </Col>
                                    <Col className="m03-1-mobile">
                                        <img style={{ height: '60px', float: "left" }} src={require('./img/main/circle3.png').default} />
                                        <h5 style={{ marginTop: '10px', fontSize: '14px', lineHeight: '20px' }}>맞는 승마 프로그램을<br />찾고 싶으신 분</h5>
                                    </Col>
                                    <Col className="m04-1-mobile">
                                        <img style={{ height: '60px', float: "left" }} src={require('./img/main/circle4.png').default} />
                                        <h5 style={{ marginTop: '10px', fontSize: '14px', lineHeight: '20px' }}>승마 관련 여러 정보를<br />알고 싶으신 분</h5>
                                    </Col>
                                </Col>
                            </Row>
                            <Row style={{ margin: '140px 0px 80px 0px' }}>
                                <SimpleSliderM />
                            </Row>
                            <Row style={{ backgroundColor: "#12AFDC", width: '100%', margin: 0 }}>
                                <Row className='container-mobile' >

                                    <h2 style={{ paddingTop: "40px", fontWeight: '700', color: "#fff", fontSize: '24px' }}>이제 '말타'로<br />승마를 시작해보세요!</h2>
                                    <p style={{ color: "#fff", fontSize: '12px' }}>보다 편리한 승마 서비스를 제공합니다.</p>
                                    <Row className='container-mobile' style={{ padding: 4 }}>
                                        <Button className="main-button1-mobile-app" style={{ padding: '5px' }}
                                            //  href="https://apps.apple.com/app/id1532007829" Target="_blank">
                                            onClick={() => this.appstoreBtnClicked()}>
                                            <FaApple size="19" style={{ padding: "0px" }} />
                                            <span style={{ fontSize: '14px', marginTop: '20px' }}> APP STORE</span></Button>
                                    </Row>
                                    <Row className='container-mobile' style={{ padding: 4 }}>

                                        <Button className="main-button2-mobile-app" style={{ padding: '5px' }}
                                            //  href="https://play.google.com/store/apps/details?id=com.timehive.marlta" Target="_blank">
                                            onClick={() => this.playStoreBtnClicked()}>
                                            <FaGooglePlay />
                                            <span style={{ fontSize: '14px' }}> PLAY STORE</span>
                                        </Button>
                                    </Row>
                                </Row>
                                <Col>
                                    <img style={{ height: '160px', marginTop: "20px", marginBottom: '30px' }} src={require('./img/main/download.png').default} />
                                </Col>
                            </Row>
                            <Row className='container-mobile ' style={{ margin: 0, padding: 0 }}>
                                <h2 style={{ margin: "150px 0px 40px 0px", fontSize: '28px' }}><span style={{ fontWeight: '200' }}>말타</span>
                                    <span style={{ fontWeight: '700' }}> 뉴스</span></h2>
                                <Row className='div-align-center'>
                                    <Row className="news1-1" style={{ padding: "30px 0px 10px 0px" }}>
                                        <a href="https://www.nextunicorn.kr/newsroom/91dca7f059c15e24"
                                            target="_blank" style={{ textDecoration: "none" }}>
                                            <img src={require('./img/main/news1-1.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                                            <Row className="news-title1-1" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                                                <span style={{ fontSize: "14px", fontWeight: "400" }}>서비스 무료 이용</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'베이직 요금제 출시'</span>
                                            </Row>
                                            {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2021.05.31</Row> */}
                                            <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>넥스트유니콘</Row>
                                        </a>
                                    </Row>
                                    <Row className="news1-2" style={{ padding: "30px 0px 10px 0px" }}>
                                        <a href="https://www.nextunicorn.kr/newsroom/527b95a6c4222b68"
                                            target="_blank" style={{ textDecoration: "none" }}>
                                            <img src={require('./img/main/news1-2.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                                            <Row className="news-title1-2" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                                                <span style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>한국마사회와</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'업무협약(MOU) 체결'</span>
                                            </Row>
                                            {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2021.03.24</Row> */}
                                            <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>넥스트유니콘</Row>
                                        </a>
                                    </Row>
                                    <Row className="news1-3" style={{ padding: "30px 0px 10px 0px" }}>
                                        <a href="https://www.nextunicorn.kr/newsroom/d9175a6a18eafb56"
                                            target="_blank" style={{ textDecoration: "none" }}>

                                            <img src={require('./img/main/news1-3.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                                            <Row className="news-title1-3" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                                                <span style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>승마 산업 활성화</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'승마장 5곳과 협약 체결'</span>
                                            </Row>
                                            {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2020.12.18</Row> */}
                                            <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>넥스트유니콘</Row>
                                        </a>
                                    </Row>

                                    <Row className="news1-4" style={{ padding: "30px 0px 10px 0px" }}>
                                        <a href="https://www.edaily.co.kr/news/read?newsId=01380886625864696&mediaCodeNo=257&OutLnkChk=Y"
                                            target="_blank" style={{ textDecoration: "none" }}>

                                            <img src={require('./img/main/news1-4.jpg').default} style={{ marginBottom: "5px", height: "180px", width: "256px", cursor: "pointer" }} />
                                            <Row className="news-title1-4" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", cursor: "pointer" }}>
                                                <span style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>2020 농림축산식품</span><br /><span style={{ fontSize: "22px", fontWeight: "700" }}>'창업경진대회 최우수상'</span>
                                            </Row>

                                            {/* <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "12px", cursor: "pointer" }}>2020.08.06</Row> */}
                                            <Row className="container-mobile div-align-center" style={{ color: "#b3b3b3", fontSize: "12px", cursor: "pointer" }}>이데일리</Row>

                                        </a>
                                    </Row>
                                </Row>
                            </Row>
                            <Row className='container-mobile div-align-center'>
                                <h2 style={{ paddingBottom: "30px", fontSize: '28px', paddingTop: '140px' }}><span style={{ fontWeight: '200' }}>말타</span>
                                    <span style={{ fontWeight: '700' }}> 파트너스</span></h2>
                                <Row style={{ justifyContent: "center", alignItems: "center", marginBottom: "60px" }}>
                                    <Row className='container-mobile div-align-center'>
                                        <img src={require('./img/main/notice1.jpg').default} style={{ marginBottom: '16px', height: "26px", width: '200px' }} /></Row>
                                    <Row className='container-mobile div-align-center'><img src={require('./img/main/notice2.png').default} style={{ marginBottom: '16px', height: "60px", width: '180px' }} /></Row>
                                    <Row className='container-mobile div-align-center'><img src={require('./img/main/notice3.jpeg').default} style={{ marginBottom: '16px', height: "40px", width: '180px' }} /></Row>
                                </Row>
                            </Row>
                            <Row className="phone-mobile div-align-center container-mobile" style={{ padding: 0, margin: 0 }}>
                                <Row>
                                    <h2 style={{ fontWeight: "200", fontSize: '20px', marginTop: '50px' }}>더 궁금한 점이 있으신가요?</h2>
                                </Row>
                                <Row className="phone2-mobile div-align-center">
                                    <h2><span style={{ fontWeight: "300", fontSize: '22px' }}>말타 고객센터</span><br /><span style={{ fontWeight: "700" }}>02-567-0543</span></h2>
                                    <p style={{ fontSize: '16px', marginBottom: '40px' }}>운영시간 : 평일   AM 09:30 - PM 06:30</p>
                                </Row>
                            </Row>

                            <FooterM />
                        </>
                    )
                        : (
                            <>
                            </>
                        )
                }
                {
                    this.state.index == 1 && (
                        <>
                            <MobileHome2 />

                        </>
                    )
                }
            </Row>
        );
    }
}

export default MobileHome;