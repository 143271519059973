
import React, { Component } from 'react';
import axios from 'axios'
import { Row, Col, Button, Input, FormGroup, Label } from 'reactstrap';

class EventSuccess extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div class='event-back2' style={{ height: '100vh', width: '100%', alignItems: 'center' }}>
                 <Row class='text-center' style={{ width: '100%', justifyContent: 'center' }}>
                                <Col xs='12' className='text-left' style={{ maxWidth: '700px', backgroundColor: '#fff', borderRadius: '10px', padding: 20, margin: 10 }}>
                                    <h3 style={{ marginTop: 5 }}>성공적으로 제보 되었습니다.</h3>
                                    <h4>감사합니다</h4>
                                    <div style={{ fontSize: 15 }}>창을 닫아주시고 말타를 계속 사용해주세요.</div>
                                </Col>
                            </Row>
            </div>
        )
    }
}

export default EventSuccess