import React, { Component } from "react";
import Slider from "react-slick";
import { Button, Container } from 'reactstrap';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}
export default class SimpleSlider extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
    page:0
  };

  slideOnChanged = (index) => {
    this.setState({
      page:index
    })
  }

  buttonClicked = (index) => {
    this.slider.slickGoTo(index);
    this.setState({
      page:index
    })
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: this.slideOnChanged,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      arrows: true
    };
    return (
      <Container>
        <h2 style={{ fontWeight: '700',fontSize:'36px' }}>말타 파트너스 승마클럽</h2>
        <p style={{ fontSize:'20px' }}>말타를 통해 승마장 관리, 홍보는 물론 신규회원 유치가 쉽도록 도와드립니다!</p>

        {  this.state.page == 0 && (
                        <div className="slick-button">
                            <Button className="slick-button0" style={{ backgroundColor: "#12AFDC", color: "#fff",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(0)}>신규회원 모집</Button>
                            <Button className="slick-button1" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(1)}>스케쥴 공유</Button>
                            <Button className="slick-button2" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(2)}>자동 쿠폰관리</Button>
                        </div>
                    )
                }
                {  this.state.page == 1 && (
                        <div className="slick-button">
                            <Button className="slick-button0" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(0)}>신규회원 모집</Button>
                            <Button className="slick-button1" style={{ backgroundColor: "#12AFDC", color: "#fff",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(1)}>스케쥴 공유</Button>
                            <Button className="slick-button2" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(2)}>자동 쿠폰관리</Button>
                        </div>
                    )
                }
                {  this.state.page == 2 && (
                        <div className="slick-button">
                            <Button className="slick-button0" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(0)}>신규회원 모집</Button>
                            <Button className="slick-button1" style={{ backgroundColor: "#F8F9FA", color: "rgb(19, 19, 19)" ,fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px'}} onClick={e => this.buttonClicked(1)}>스케쥴 공유</Button>
                            <Button className="slick-button2" style={{ backgroundColor: "#12AFDC", color: "#fff",fontSize:'24px', padding:'16px 10px 16px 10px',borderRadius:'60px' }} onClick={e => this.buttonClicked(2)}>자동 쿠폰관리</Button>
                        </div>
                    )
                }

        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <div>
              <img className="service-img1" style={{height:"420px", width:"420px"}} src={require("./img/main/0001.png").default}/>
            <h2 style={{paddingTop:'50px',fontWeight:'700'}}>신규회원 모집은 '말타'에서!</h2>
            <p>승마에 관심있는 사람들은 모두 모인 승마 플랫폼 '말타'<br/>
            승마장을 무료로 등록하고 신규회원이 늘어나는 홍보효과를 느껴보세요!</p>
          </div>

          <div>
          <img className="service-img2" style={{height:"420px", width:"216px"}} src={require("./img/main/0002.png").default} />
            <h2 style={{paddingTop:'50px',fontWeight:'700'}}>실시간 연동 기능</h2>
            <p>전화, 문자 등으로 일정 공유는 그만!<br/>
            승마클럽과 코치, 회원이 실시간으로 일정을 확인할 수 있습니다.</p>
          </div>
          
          <div>
          <img className="service-img3" style={{height:"420px", width:"216px"}} src={require("./img/main/0003.png").default} />
            <h2 style={{paddingTop:'50px',fontWeight:'700'}}>더 이상 수기로 회원 리스트를 관리하지 마세요!</h2>
            <p>코치와 회원이 각자 쿠폰을 관리할 필요 없습니다.<br/>
            자동으로 회원의 쿠폰을 차감해줌으로써 업무의 효율성은 물론 회원의 신뢰감까지 UP!</p>
          </div>
          
        </Slider>
      </Container>
    );
  }
}