import React, { Component } from "react";
import Slider from "react-slick";
import { Button , Col} from 'reactstrap';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}
export default class ClubList extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
    
  };

  slideOnChanged = (index) => {
    this.setState({
      page:index
    })
  }


  render() {
    const settings = {
      dots: false,
      infinite: true,
      centerPadding: "60px",
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      rows: 2,
      afterChange: this.slideOnChanged,
      // nextArrow: <SampleNextArrow />,
      // prevArrow: <SamplePrevArrow />,
      arrows: true
    };
    return (
      <div>
        <h2>말타 협약 승마클럽</h2>

        <Slider ref={slider => (this.slider = slider)} {...settings}>
          <div>
              <img className="service-img" style={{height:"90px", width:"160px"}} src={require("./img/main/club_list/01.jpeg").default}/>
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/02.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/03.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/04.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/05.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/06.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/07.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/08.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/09.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/10.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/11.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/12.jpeg").default} />
          </div>
          <div>
            <img className="service-img" style={{ height: "90px", width: "160px" }} src={require("./img/main/club_list/13.jpeg").default} />
          </div>
          

        </Slider>
      </div>
    );
  }
}