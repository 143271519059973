

import React, { useEffect } from "react";
import axios from 'axios';

const Kakao = () => {
    useEffect(() => {
        const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_ID_IOS}&response_type=code&redirect_uri=${process.env.REACT_APP_KAKAO_URL_IOS}&scope=account_email`;
        alert(KAKAO_AUTH_URL)
        window.location.href = KAKAO_AUTH_URL;
    })
    return <></>
}

export default Kakao;

