import React, { Component } from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import { FaGooglePlay, FaApple } from "react-icons/fa";
import Home2 from './Home2';
import SimpleSlider from './SimpleSlider';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './Footer/Footer';

import axios from 'axios'


import background from "./img/main/header-background.jpg";
import { osVersion, osName, browserName, browserVersion, mobileVendor, mobileModel, isMobileOnly, isTablet, isBrowser, isAndroid, isIOS } from 'react-device-detect';

class Home extends Component {
    constructor(props) {
        super(props);

        const { search } = this.props.location;
        const urlParams = new URLSearchParams(search)
        const linkParam = urlParams.get('link_source')
        // alert(linkParam);
        this.getIPAddress(linkParam);
        this.state = {
            index: 0
        }
    }

    getIPAddress = (linkParam) => {

        var log_id = window.sessionStorage.getItem('lid');
        if (log_id == undefined) {
            axios.get('https://geolocation-db.com/json/').then(res => {
                this.postLinkIP(linkParam, res.data.IPv4);
            })
        }
    }

    postLinkIP = (linkParam, ipAddress) => {
        var param = {};

        if (linkParam) {
            param.link_source = linkParam;
        }
        if (ipAddress) {
            param.ip_address = ipAddress;
        }
        if (osVersion) {
            param.os_version = osVersion;
        }
        if (osName) {
            param.os_name = osName;
        }
        if (browserName) {
            param.browser_name = browserName;
        }
        if (browserVersion) {
            param.browser_version = browserVersion;
        }
        if (mobileVendor) {
            param.mobile_vendor = mobileVendor;
        }
        if (mobileModel) {
            param.mobile_model = mobileModel;
        }
        if (isMobileOnly) {
            param.is_mobile = isMobileOnly;
        }
        if (isTablet) {
            param.is_tablet = isTablet;
        }
        if (isBrowser) {
            param.is_browser = isBrowser;
        }
        if (isAndroid) {
            param.is_android = isAndroid;
        }
        if (isIOS) {
            param.is_ios = isIOS;
        }

        // alert(JSON.stringify(param));

        axios.post('http://3.38.226.155:8080/admin/homepage/logs', param).then(res => {
            if (res.data.status == 1) {
                window.sessionStorage.setItem('lid', res.data.data.log_id);
            } else {
                // alert(res.data.message);
            }
        }).catch(error => {
            // alert(error);
        });
    }

    appstoreBtnClicked = () => {
        this.logClick(1, 0);
        window.open('https://apps.apple.com/app/id1532007829', '_blank').focus();
    }

    playStoreBtnClicked = () => {
        this.logClick(0, 1);
        window.open('https://play.google.com/store/apps/details?id=com.timehive.marlta', '_blank').focus();
    }

    logClick = (as, ps) => {

        var log_id = window.sessionStorage.getItem('lid');
        if (log_id > 0) {
            var param = {}
            if (as > 0) { param.click_app_store = 1; }
            if (ps > 0) { param.click_play_store = 1; }
            axios.post('http://3.38.226.155:8080/admin/homepage/logs/click', {
                log_id: log_id,
                param: param
            }).then(res => {
                if (res.data.status == 1) {
                    // alert('로그 : ' + JSON.stringify(res.data.data.log_id));
                } else {
                    // alert(res.data.message);
                }
            }).catch(error => {
                // alert(error);
            });
        }
    }


    movetoService = () => {
        window.location.href = '/#/service'
    }

    moveToTerms = () => {
        window.location.href = '/#/terms'
    }
    render() {
        return (

            <Row style={{ margin: 0 }}>
                <Col xs='12' className="main" style={{ padding: 0 }}>
                    <Col style={{ backgroundImage: `url(${background})`, width: '100%', backgroundSize: 'cover', padding: 0 }}>
                        <Container>
                            <Row>
                                <Col xs={{ size: 5, order: 0, offset: 0 }} style={{ marginBottom: '150px' }}>
                                    <Col className="main-m">
                                        <h6 style={{ fontSize: "16px" }}>기승 예약, 스케쥴 관리 플랫폼</h6>
                                        <h1 className="main-h1">힐링의 시작, 말타</h1>
                                        <p style={{ fontSize: "20px", paddingTop: '10px' }}>말타와 함께 승마장의<br />영업효과를 증명해보세요!</p>
                                    </Col>
                                    <Row>
                                        <Col >
                                            <Button onClick={() => this.movetoService()} className="main-button1" style={{ padding: '18px 10px 18px 10px', borderRadius: '50px', margin: '24px 0px 0px 0px', borderWidth: '2px', borderStyle: 'solid' }}>서비스 소개</Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={() => this.moveToTerms()} className="main-button2" style={{ padding: '18px 10px 18px 10px', borderRadius: '50px', margin: '24px 0px 0px 0px', borderWidth: '2px', borderStyle: 'solid' }}>파트너스 문의</Button>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={{ size: 5, order: 0, offset: 0 }} >
                                    <img className="main-img" src={require('./img/main/header-iphone.png').default} />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Col>
                <Container>
                    <Row className='justify-content-md-center'>

                        {
                            this.state.index == 0 ? (
                                <Row className="main-btn">
                                    <Button className="main-btn1" style={{ width: '400px' }} onClick={e => this.setState({ index: 0 })}>회원 앱 기능보기</Button>
                                    <Button className="main-btn2" style={{ width: '400px' }} onClick={e => this.setState({ index: 1 })}>코치 앱 기능보기</Button>
                                </Row>
                            ) : (
                                <Row className="main-btn">
                                    <Button className="main-btn2" style={{ width: '400px' }} onClick={e => this.setState({ index: 0 })}>회원 앱 기능보기</Button>
                                    <Button className="main-btn1" style={{ width: '400px' }} onClick={e => this.setState({ index: 1 })}>코치 앱 기능보기</Button>
                                </Row>
                            )
                        }
                    </Row>
                </Container>

                {
                    this.state.index == 0 ? (
                        <>
                            <Row className="justify-content-md-center" style={{ margin: "120px 0px 40px 0px", minWidth: 1080 }}>
                                <h2>
                                    <span style={{ fontWeight: '200', fontSize: '36px' }}>편리하고 빠르게</span><br />
                                    <span style={{ fontWeight: '700', fontSize: '36px' }}>기승예약</span>
                                    <span style={{ fontWeight: '200', fontSize: '36px' }}>을 할 수 있습니다</span>
                                </h2>
                            </Row>
                            <Container style={{ marginBottom: "160px" }}>
                                <Row className="justify-content-md-center">
                                    <Col xs={{ size: 5, order: 0, offset: 0 }}>
                                        <img className="mockup-img" src={require('./img/main/member-mockup.png').default} />
                                    </Col>
                                    <Col className="app-service" xs={{ size: 4, order: 0, offset: 1 }}>
                                        <h2 className="app-service2">기승자를 위한 맞춤 추천</h2>
                                        <p className="app-service3">
                                            말타는 가까운 위치의 승마장을 상단에 띄워 기승자가 보다 편리하게 실시간 예약이 가능한 기반의 서비스입니다.</p>
                                    </Col>
                                </Row>
                            </Container>

                            <Row style={{ padding: 0, margin: 0 }}>
                                <Col style={{ padding: 0 }}>
                                    <Col style={{ backgroundColor: "#12AFDC", height: '250px', width: '100%', padding: 0 }}>
                                        <Container style={{ maxWidth: "1000px" }}>
                                            <Row>
                                                <Row className="app-service-group">
                                                    <Col xs={6}>
                                                        <h2 className="app-service4" >말타는<br />
                                                            <span className="app-service5" >이런 분들께<br />추천 드립니다!</span></h2>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <img className="img1234" src={require('./img/main/people.png').default} />
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Col>
                            </Row>

                            <Row style={{ paddingTop: "40px", margin: '0px 20px 0px 20px' }}>
                                <Container>
                                    <Row className="align-middle" style={{ display: "flex" }}>
                                        <Col className="m01-1" style={{ width: '200px' }}>
                                            <img style={{ width: '80px', height: '80px', float: "left", marginLeft: "-40px" }} src={require('./img/main/circle1.png').default} />
                                            <h5 style={{ marginTop: '26px' }}>전화 상담 및 예약이 번거로우신 분</h5>
                                        </Col>
                                        <Col className="m02-1" style={{ width: '200px' }}>
                                            <img style={{ width: '80px', height: '80px', float: "left", marginLeft: "-40px" }} src={require('./img/main/circle2.png').default} />
                                            <h5 style={{ marginTop: '26px' }}>가까운 승마장을 찾고 싶으신 분</h5>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: "flex" }}>
                                        <Col className="m03-1" style={{ width: '200px' }}>
                                            <img style={{ width: '80px', height: '80px', float: "left", marginLeft: "-40px" }} src={require('./img/main/circle3.png').default} />
                                            <h5 style={{ marginTop: '26px' }}>맞는 승마 프로그램을 찾고 싶으신 분</h5>
                                        </Col>
                                        <Col className="m04-1" style={{ width: '200px' }}>
                                            <img style={{ width: '80px', height: '80px', float: "left", marginLeft: "-40px" }} src={require('./img/main/circle4.png').default} />
                                            <h5 style={{ marginTop: '26px' }}>승마 관련 여러 정보를 알고 싶으신 분</h5>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>

                            <Container>
                                <Row className="justify-content-md-center">
                                    <Col style={{ margin: '160px 0px', padding: '30px' }}>
                                        <SimpleSlider />
                                    </Col>
                                </Row>
                            </Container>
                            <Row style={{ padding: 0, margin: 0 }}>
                                <Col style={{ padding: 0 }}>
                                    <Col style={{ backgroundColor: "#12AFDC", width: '100%' }}>
                                        <Container style={{ maxWidth: "1000px" }}>
                                            <Row style={{ paddingBottom: '30px' }}>
                                                <Col xs={{ size: 5, order: 0, offset: 1 }}>
                                                    <Col>
                                                        <h2 style={{ paddingTop: "60px", fontWeight: '700', color: "#fff", fontSize: '36px' }}>이제 '말타'로<br />승마를 시작해보세요!</h2>
                                                        <p style={{ color: "#fff", fontSize: '18px' }}>보다 편리한 승마 서비스를 제공합니다.</p>
                                                    </Col>
                                                    <Row className="justify-content-md-center" >
                                                        <Col xs={{ size: 6, order: 0, offset: 0 }}>
                                                            <Button
                                                                style={{ padding: '18px 10px 18px 10px', borderRadius: '50px', margin: '24px 0px 0px 0px', borderWidth: '2px', borderStyle: 'solid', margin: '2px 0px 0px 0px' }} className="main-button1"
                                                                // href="https://apps.apple.com/app/id1532007829" Target="_blank">
                                                                onClick={() => this.appstoreBtnClicked()}>
                                                                <FaApple size="22" style={{ marginBottom: "4px" }} />
                                                                <span style={{ fontSize: '18px' }}> APP STORE</span>
                                                            </Button>
                                                        </Col>
                                                        <Col xs={{ size: 6, order: 0, offset: 0 }}>
                                                            <Button style={{ padding: '18px 10px 18px 10px', borderRadius: '50px', margin: '24px 0px 0px 0px', borderWidth: '2px', borderStyle: 'solid', margin: '2px 0px 0px 0px' }} className="main-button2"
                                                                // href="https://play.google.com/store/apps/details?id=com.timehive.marlta" Target="_blank">
                                                                onClick={() => this.playStoreBtnClicked()}>
                                                                <FaGooglePlay size="20" />
                                                                <span style={{ fontSize: '18px' }}> PLAY STORE</span>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={{ size: 5, order: 0, offset: 1 }}>
                                                    <img style={{ width: '250px', height: '250px', marginTop: "46px" }} src={require('./img/main/download.png').default} />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Container style={{ padding: 0 }} >
                                    <h2 style={{ margin: "150px 0px 40px 0px", fontSize: '36px' }}><span style={{ fontWeight: '200' }}>말타</span>
                                        <span style={{ fontWeight: '700' }}> 뉴스</span></h2>

                                    <Row style={{ justifyContent: "center", alignItems: "center", paddingLeft: "10px" }}>

                                        <Col xs='3' className="news1-1" style={{ padding: "30px 4px 10px 4px" }}>
                                            <a href="https://www.nextunicorn.kr/newsroom/91dca7f059c15e24"
                                                target="_blank" style={{ textDecoration: "none" }}>
                                                <img src={require('./img/main/news1-1.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                                                <Col className="news-title1-1" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", textAlign: "left", cursor: "pointer", paddingLeft: "6px" }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "400" }}>서비스 무료 이용</span><br /><span style={{ fontSize: "24px", fontWeight: "700" }}>'베이직 요금제 출시'</span>
                                                </Col>
                                                <Col className="news-p">
                                                    <Row>
                                                        {/* <Col className="news-p1" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", textAlign: "left", fontSize: "15px", cursor: "pointer" }}>2021.05.31</Col> */}

                                                        <Col className="news-p2" style={{ textAlign: "right", color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "15px", cursor: "pointer" }}>넥스트유니콘</Col>
                                                    </Row>
                                                </Col>
                                            </a>
                                        </Col>

                                        <Col xs='3' className="news1-2" style={{ padding: "30px 4px 10px 4px" }}>
                                            <a href="https://www.nextunicorn.kr/newsroom/527b95a6c4222b68"
                                                target="_blank" style={{ textDecoration: "none" }}>

                                                <img src={require('./img/main/news1-2.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                                                <Col className="news-title1-2" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", textAlign: "left", cursor: "pointer", paddingLeft: "6px" }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "400", cursor: "pointer" }}>한국마사회와</span><br /><span style={{ fontSize: "24px", fontWeight: "700" }}>'업무협약(MOU) 체결'</span>
                                                </Col>
                                                <Col className="news-p">
                                                    <Row>
                                                        {/* <Col className="news-p1" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", textAlign: "left", fontSize: "15px", cursor: "pointer" }}>2021.03.24</Col> */}

                                                        <Col className="news-p2" style={{ textAlign: "right", color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "15px", cursor: "pointer" }}>넥스트유니콘</Col>
                                                    </Row>
                                                </Col>
                                            </a>
                                        </Col>

                                        <Col xs='3' className="news1-3" style={{ padding: "30px 4px 10px 4px" }}>
                                            <a href="https://www.nextunicorn.kr/newsroom/d9175a6a18eafb56"
                                                target="_blank" style={{ textDecoration: "none" }}>

                                                <img src={require('./img/main/news1-3.jpg').default} style={{ height: "180px", width: "256px", marginBottom: "5px", cursor: "pointer" }} />
                                                <Col className="news-title1-3" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", textAlign: "left", cursor: "pointer", paddingLeft: "6px" }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "400", cursor: "pointer" }}>승마 산업 활성화</span><br /><span style={{ fontSize: "24px", fontWeight: "700" }}>'승마장 5곳과 협약 체결'</span>
                                                </Col>
                                                <Col className="news-p">
                                                    <Row>
                                                        {/* <Col className="news-p1" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", textAlign: "left", fontSize: "15px", cursor: "pointer" }}>2020.12.18</Col> */}

                                                        <Col className="news-p2" style={{ textAlign: "right", color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "15px", cursor: "pointer" }}>넥스트유니콘</Col>
                                                    </Row>
                                                </Col>
                                            </a>
                                        </Col>

                                        <Col xs='3' className="news1-4" style={{ padding: "30px 4px 10px 4px" }}>
                                            <a href="https://www.edaily.co.kr/news/read?newsId=01380886625864696&mediaCodeNo=257&OutLnkChk=Y"
                                                target="_blank" style={{ textDecoration: "none" }}>

                                                <img src={require('./img/main/news1-4.jpg').default} style={{ marginBottom: "5px", height: "180px", width: "256px", cursor: "pointer" }} />
                                                <Col className="news-title1-4" style={{ fontSize: "22px", fontWeight: "600", color: "#363636", lineHeight: "24px", textAlign: "left", cursor: "pointer", paddingLeft: "6px" }}>
                                                    <span style={{ fontSize: "16px", fontWeight: "400", cursor: "pointer" }}>2020 농림축산식품</span><br /><span style={{ fontSize: "24px", fontWeight: "700" }}>'창업경진대회 최우수상'</span>
                                                </Col>
                                                <Col className="news-p">
                                                    <Row>
                                                        {/* <Col className="news-p1" style={{ color: "#b3b3b3", padding: "6px 0px 0px 0px", textAlign: "left", fontSize: "15px", cursor: "pointer" }}>2020.08.06</Col> */}
                                                        <Col className="news-p2" style={{ textAlign: "right", color: "#b3b3b3", padding: "6px 0px 0px 0px", fontSize: "15px", cursor: "pointer" }}>이데일리</Col>
                                                    </Row>
                                                </Col>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                            <Row>
                                <Container>
                                    <h2 style={{ paddingBottom: "30px", fontSize: '36px', paddingTop: '150px' }}><span style={{ fontWeight: '200' }}>말타</span>
                                        <span style={{ fontWeight: '700' }}> 파트너스</span></h2>
                                    <Row>
                                        <Col style={{ paddingLeft: "30px", justifyContent: "center", alignItems: "center", marginBottom: "140px" }}>
                                            <Col>
                                                <img src={require('./img/main/notice1.jpg').default} style={{ margin: "40px", height: "50px" }} />
                                                <img src={require('./img/main/notice2.png').default} style={{ margin: "30px 30px 42px 30px", height: "92px" }} />
                                                <img src={require('./img/main/notice3.jpeg').default} style={{ margin: "30px", height: "70px" }} />
                                            </Col>
                                        </Col>
                                    </Row>
                                </Container>
                            </Row>
                            <Row xs='12' style={{ padding: 0, margin: 0, minWidth: 1080 }}>
                                <Col className="phone">
                                    <Col>
                                        <h2 style={{ fontWeight: "200" }}>더 궁금한 점이 있으신가요?</h2>
                                    </Col>
                                    <Col className="phone2">
                                        <h2><span style={{ fontWeight: "200", fontSize: '36px' }}>말타 고객센터</span>  <span style={{ fontWeight: "700" }}>02-567-0543</span></h2>
                                        <p style={{ fontSize: '22px' }}>운영시간 : 평일   AM 09:30 - PM 06:30</p>
                                    </Col>
                                </Col>
                            </Row>
                            <Footer />
                        </>
                    )
                        : (
                            <>
                            </>
                        )
                }
                {
                    this.state.index == 1 && (
                        <>
                            <Home2 />

                        </>
                    )
                }


            </Row>
        );
    }
}

export default Home;