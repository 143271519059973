import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, BrowserRouter } from 'react-router-dom';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

export default class Navbarmenu extends React.Component {
        constructor(props) {
          super(props);
      
          this.toggle = this.toggle.bind(this);
          this.state = {
            isOpen: false
          };
        }
        toggle() {
          this.setState({
            isOpen: !this.state.isOpen
          });
        }

        render() {
            return (
              <div>
                <BrowserRouter>
                <Navbar light expand="md">
                  <NavbarBrand href="/" className="mr-auto">
                  <img style={{height:"60px", width:"60px", margin:'10px'}} src={require("../img/main/logo.png").default } />
                  </NavbarBrand>
                  <NavbarToggler onClick={this.toggle} className="mr-2"/>
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                      <NavItem>
                        <NavLink exact style={{fontSize:'18px', color:'#000', margin:'0px 12px 0px 10px'}} href="/">HOME</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink exact style={{fontSize:'18px', color:'#000', margin:'0px 12px 0px 10px'}} href="/#/service">서비스 소개</NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink exact style={{fontSize:'18px', color:'#000', margin:'0px 12px 0px 10px'}} href="/#/terms">파트너스 문의</NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
                </BrowserRouter>
              </div>
            );
          }
        }
